/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BusinessServices Component
 * Description: Component for managing business services, including listing, selecting, and deleting services.
 *
 * Props:
 * - onSelectService: (service: IService) => void - Callback function triggered when a service is selected to edit.
 *
 * Purpose:
 * - Allows users to view and manage their business services.
 * - Provides the ability to add, select, and delete services.
 *
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import s from './BusinessService.module.scss';
import { IService } from 'interfaces/service.interface';
import { palette } from '../../../styled/common';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import { api } from 'helpers/auth-axios';
import { Input, LoaderOverlay, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { NotFound } from '../../team/list/serviceProviderList/ServiceProviderList';
import { useTranslation } from 'react-i18next';
import LabelService from './LabelService';
import SearchField from '@components/common/search/SearchField';
import MultiSelect from '@components/common/select/MultiSelect';
import * as XLSX from 'xlsx';
import PackageService from '../PromotionsAndGiftCard/PackagesService';

const BusinessServices = ({
    onSelectService,
    createService,
    isSale,
    isEdit,
    selectedService,
}: {
    onSelectService: any;
    createService?: any;
    isSale?: any;
    isEdit?: any;
    selectedService?: any;
}) => {
    const { t } = useTranslation();
    const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [service, setService] = useState<any>({});
    const [filteredService, setFilteredService] = useState<any>({});
    const [tabValue, setTabValue] = useState(0);
    const [createLabelService, setCreateLabelService] = useState(false);
    const [PackageView, setPackageView] = useState(false);
    const [editCategory, setEditCategory] = useState('');
    const [openSnackbar] = useSnackbar();
    const [categories, setCategories] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState<any>('');
    const [selectedCategory, setSelectedCategory] = useState<any[]>([]);
    const navigate = useNavigate();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        getLabelServices();
    }, []);

    useEffect(() => {
        if (tabValue == 0) {
            getServices();
        } else if (tabValue == 1) {
            getLabelServices();
        } else if (tabValue == 2) {
            setPackageView(true);
        }
    }, [tabValue]);

    const getServices = async () => {
        setLoading(true);
        await api
            .get('/services')
            .then((res: any) => {
                setLoading(false);
                if (res.data?.length) {
                    if (isSale) {
                        setService(res.data);
                    } else {
                        // const groupedData = res.data.reduce((groups: any, item: any) => {
                        //     const labelName = item?.label_id?.name;
                        //     if (!groups[labelName]) {
                        //         groups[labelName] = [];
                        //     }
                        //     groups[labelName].push(item);
                        //     return groups;
                        // }, {});
                        const groupedData = res.data.reduce((groups: any, item: any) => {
                            const labelName = item?.label_id?.name;
                            if (!groups[labelName]) {
                                groups[labelName] = [];
                            }
                            groups[labelName].push(item);
                            return groups;
                        }, {});

                        // 🔹 Сортируем услуги внутри каждой категории (учитывает латиницу и кириллицу)
                        Object.keys(groupedData).forEach(category => {
                            groupedData[category].sort((a: any, b: any) =>
                                a.name.localeCompare(b.name, ['ru', 'en'])
                            );
                        });

                        setFilteredService(groupedData);
                        setService(groupedData);

                        setFilteredService(groupedData);
                        setService(groupedData);
                    }
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const getLabelServices = async () => {
        setLoading(true);
        await api
            .get('/labeled-services')
            .then((res: any) => {
                setLoading(false);
                if (res.data) {
                    setCategories(res.data);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const selectService = (service: IService) => {
        onSelectService(service);
    };

    const deleteService = (id: any) => {
        setLoading(true);
        api.delete(`/services/${id}`).then((res: any) => {
            if (res?.data) {
                openSnackbar(t('Service deleted successfully!'));
                setOpenDeleteModel(false);
                getServices();
            } else {
                openSnackbar(t('Failed to delete Service!'));
                setOpenDeleteModel(false);
            }
        });
        setLoading(false);
        setOpenDeleteModel(false);
    };

    const deleteCategoryService = (id: any) => {
        setLoading(true);
        api.delete(`/labeled-services/${id}`).then((res: any) => {
            if (res?.data) {
                openSnackbar(t('Category deleted successfully!'));
                setOpenDeleteModel(false);
                const deleteCategory: any = categories.filter((item: any) => {
                    return item._id !== id;
                });
                setCategories(deleteCategory);
            } else {
                openSnackbar(t('Failed to delete Category!'));
                setOpenDeleteModel(false);
            }
        });
        setLoading(false);
        setOpenDeleteModel(false);
    };

    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchQuery(event.target.value);
    // };

    useEffect(() => {
        if (selectedCategory?.length) {
            const filteredData = Object.keys(filteredService).reduce(
                (filteredGroups: any, key: string) => {
                    if (selectedCategory?.some((e: any) => e?.label == key)) {
                        filteredGroups[key] = filteredService[key];
                    }
                    return filteredGroups;
                },
                {}
            );
            setService(filteredData);
        } else {
            setService(filteredService);
        }
    }, [selectedCategory]);

    // const exportToExcel = () => {
    //     if (!service || Object.keys(service).length === 0) {
    //         openSnackbar(t('No data to export!'));
    //         return;
    //     }

    //     const exportData: any = [];

    //     // Сортируем данные по категориям
    //     Object.keys(service).forEach(category => {
    //         const servicesInCategory = service[category].filter(
    //             (item: any) => item.available_for_boooking === true
    //         );

    //         if (servicesInCategory.length > 0) {
    //             // Добавляем строку-заголовок категории (жирный шрифт)
    //             exportData.push({
    //                 Category: category || 'Без категории',
    //                 Name: '',
    //                 Price: '',
    //                 Description: '',
    //             });

    //             // Добавляем услуги под заголовком категории
    //             servicesInCategory.forEach((item: any) => {
    //                 exportData.push({
    //                     Category: '', // Пустое, чтобы не дублировать название категории
    //                     Name: item.name,
    //                     Price: item.price,
    //                     Description: item.description,
    //                 });
    //             });

    //             // Добавляем пустую строку между категориями
    //             exportData.push({ Category: '', Name: '', Price: '', Description: '' });
    //         }
    //     });

    //     if (exportData.length === 0) {
    //         openSnackbar(t('No available services to export!'));
    //         return;
    //     }

    //     // Создаём рабочую книгу и лист
    //     const worksheet = XLSX.utils.json_to_sheet(exportData, { skipHeader: true });
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Services');

    //     // Добавляем заголовки для колонок в Excel
    //     XLSX.utils.sheet_add_aoa(worksheet, [['Category', 'Name', 'Price', 'Description']], {
    //         origin: 'A1',
    //     });

    //     // Записываем файл Excel
    //     XLSX.writeFile(workbook, 'BusinessServices.xlsx');
    // };

    const exportToExcel = () => {
        if (!service || Object.keys(service).length === 0) {
            openSnackbar(t('No data to export!'));
            return;
        }

        const exportData: any = [];

        // 🔹 Сортируем категории по алфавиту
        const sortedCategories = Object.keys(service).sort((a, b) =>
            a.localeCompare(b, ['ru', 'en'])
        );

        sortedCategories.forEach(category => {
            let servicesInCategory = service[category].filter(
                (item: any) => item.available_for_boooking === true
            );

            // 🔹 Сортируем услуги внутри категории (учитывает латиницу и кириллицу)
            servicesInCategory = servicesInCategory.sort((a: any, b: any) =>
                a.name.localeCompare(b.name, ['ru', 'en'])
            );

            if (servicesInCategory.length > 0) {
                // Добавляем строку-заголовок категории (жирный шрифт)
                exportData.push({
                    Category: category || 'Без категории',
                    Name: '',
                    Price: '',
                    Description: '',
                });

                // Добавляем услуги под заголовком категории
                servicesInCategory.forEach((item: any) => {
                    exportData.push({
                        Category: '', // Пустое, чтобы не дублировать название категории
                        Name: item.name,
                        Price: item.price,
                        Description: item.description,
                    });
                });

                // Добавляем пустую строку между категориями
                exportData.push({ Category: '', Name: '', Price: '', Description: '' });
            }
        });

        if (exportData.length === 0) {
            openSnackbar(t('No available services to export!'));
            return;
        }

        // Создаём рабочую книгу и лист
        const worksheet = XLSX.utils.json_to_sheet(exportData, { skipHeader: true });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Services');

        // Добавляем заголовки для колонок в Excel
        XLSX.utils.sheet_add_aoa(worksheet, [['Category', 'Name', 'Price', 'Description']], {
            origin: 'A1',
        });

        // Записываем файл Excel
        XLSX.writeFile(workbook, 'BusinessServices.xlsx');
    };

    return (
        <>
            {createLabelService ? (
                <LabelService
                    onClose={() => {
                        setCreateLabelService(false);
                        setEditCategory('');
                    }}
                    onCreate={() => {
                        setCreateLabelService(false);
                        getLabelServices();
                        setEditCategory('');
                    }}
                    onEdit={editCategory}
                />
            ) : isSale ? (
                <Wrapper>
                    {loading && <LoaderOverlay />}

                    <Row>
                        <HeaderCustom col={12} style={{ marginBottom: '1rem' }}>
                            <Heading>{t('Services')}</Heading>
                            <HeaderText></HeaderText>
                            <Button
                                label={t('Add service')}
                                bgtype="secondary"
                                ifClicked={() =>
                                    createService ? createService() : navigate('/services/create')
                                }></Button>
                        </HeaderCustom>

                        <Col lg={12}>
                            <Card>
                                <Wrapper>
                                    <Row>
                                        {service?.length
                                            ? service.map((service: any, i: any) => (
                                                  <Col key={i} sm={6} md={6} lg={4}>
                                                      {isSale ? (
                                                          _userData?.user_login == 'user' ? (
                                                              <DeleteIcon
                                                                  isSale={isSale}
                                                                  style={{
                                                                      background: 'transparent',
                                                                      width: 'auto',
                                                                  }}>
                                                                  <div
                                                                      style={{
                                                                          position: 'relative',
                                                                          display: 'flex',
                                                                          gap: '0.5rem',
                                                                      }}>
                                                                      <i
                                                                          className="fal fa-trash-alt"
                                                                          style={{ color: 'red' }}
                                                                          onClick={() => {
                                                                              deleteModel(
                                                                                  service._id
                                                                              );
                                                                          }}></i>
                                                                      <i
                                                                          className="fal fa-edit"
                                                                          style={{ color: 'black' }}
                                                                          onClick={() =>
                                                                              isEdit(service)
                                                                          }></i>
                                                                  </div>
                                                              </DeleteIcon>
                                                          ) : (
                                                              ''
                                                          )
                                                      ) : _userData?.user_login == 'user' ? (
                                                          <DeleteIcon
                                                              onClick={() =>
                                                                  deleteModel(service._id)
                                                              }>
                                                              <i className="fal fa-trash-alt"></i>
                                                              {isSale && isSale && (
                                                                  <i
                                                                      className="fal fa-edit"
                                                                      style={{ color: 'black' }}
                                                                      onClick={() =>
                                                                          isEdit(service)
                                                                      }></i>
                                                              )}
                                                          </DeleteIcon>
                                                      ) : (
                                                          ''
                                                      )}

                                                      <ServiceCard
                                                          onClick={() => selectService(service)}
                                                          className={`${s.service} service-color-${
                                                              service.color
                                                          }--bg cursor-pointer
                                                  ${
                                                      isSale && selectedService?._id === service._id
                                                          ? s.services_selectedService
                                                          : ''
                                                  }
                                                  service-color-${service.color}--bd
                                                  `}>
                                                          <PriceTag>
                                                              <PriceField>
                                                                  &nbsp;
                                                                  {
                                                                      _userData.user.business_id
                                                                          .currency
                                                                  }
                                                                  {service.price}
                                                              </PriceField>
                                                          </PriceTag>
                                                          <CustomDiv>
                                                              <ServiceName>
                                                                  {service.name}
                                                              </ServiceName>
                                                              <div>
                                                                  {service?.is_video && (
                                                                      <i className="fal fa-video"></i>
                                                                  )}
                                                              </div>
                                                          </CustomDiv>

                                                          <Time>{`${
                                                              service.duration >= 60
                                                                  ? Math.floor(
                                                                        service.duration / 60
                                                                    ) + 'h'
                                                                  : ''
                                                          } ${service.duration % 60}${t(
                                                              'min'
                                                          )}`}</Time>
                                                      </ServiceCard>
                                                  </Col>
                                              ))
                                            : loading === false && (
                                                  <NotFound>{t('No Service Found')}</NotFound>
                                              )}
                                    </Row>
                                </Wrapper>
                            </Card>
                        </Col>
                    </Row>
                    {openDeleteModel && (
                        <DeleteModel
                            id={''}
                            onDelete={() => {
                                setOpenDeleteModel(false);
                            }}
                            onClose={() => {
                                setOpenDeleteModel(false);
                            }}
                            name={t(tabValue == 1 ? 'Category' : 'Service')}
                            content={t(
                                tabValue == 1
                                    ? 'If you delete this category you will lose all information connected to their category. This action cannot be undone.'
                                    : 'If you delete this service you will lose all information connected to their service including assigned staff service, appointment service. This action cannot be undone.'
                            )}
                            loading={loading}
                            onClick={() =>
                                tabValue == 1
                                    ? deleteCategoryService(deleteId)
                                    : deleteService(deleteId)
                            }
                        />
                    )}
                </Wrapper>
            ) : (
                <Wrapper>
                    {loading && <LoaderOverlay />}
                    <TabsWrapper>
                        <Tab
                            col={4}
                            active={tabValue === 0 && true}
                            onClick={() => {
                                setTabValue(0);
                            }}>{`${t('Services')}`}</Tab>
                        <Tab
                            col={4}
                            active={tabValue === 1 && true}
                            onClick={() => {
                                setTabValue(1);
                            }}>{`${t('Service Category')}`}</Tab>
                        <Tab
                            col={4}
                            active={tabValue === 2 && true}
                            onClick={() => {
                                setTabValue(2);
                            }}>{`${t('Package services')}`}</Tab>
                    </TabsWrapper>
                    {tabValue === 2 && (
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <PackageService />
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {tabValue === 0 && (
                        <Row>
                            <HeaderCustom col={12} style={{ marginBottom: '1rem' }}>
                                <Heading>{t('Services')}</Heading>
                                <HeaderText></HeaderText>
                                <ContainerCustom>
                                    <Button
                                        label={t('Export to Excel')}
                                        bgtype="secondary"
                                        ifClicked={exportToExcel}
                                    />
                                    <Button
                                        label={t('Add service')}
                                        bgtype="secondary"
                                        ifClicked={() =>
                                            createService
                                                ? createService()
                                                : navigate('/services/create')
                                        }></Button>
                                </ContainerCustom>
                            </HeaderCustom>
                            <Col lg={12}>
                                {/* <InputGroup>
                                    <SearchField
                                        value={searchQuery}
                                        handleChange={handleChange}
                                        name={''}
                                        padding={'0'}
                                        setValue={() => {
                                            setSearchQuery('');
                                        }}
                                    />
                                </InputGroup> */}
                                <InputGroup>
                                    <MultiSelect
                                        label={t('Filter Services') ?? ''}
                                        isMulti={true}
                                        value={selectedCategory}
                                        options={categories?.map((e: any) => ({
                                            label: e?.name,
                                            value: e,
                                        }))}
                                        onChange={setSelectedCategory}
                                    />
                                </InputGroup>
                                <Card>
                                    {Object.keys(service)?.length
                                        ? Object.keys(service).map((category: any, i) => (
                                              <Wrapper key={i}>
                                                  <CategoryTitle>
                                                      {category == 'undefined' ? '' : category}
                                                  </CategoryTitle>
                                                  <Row>
                                                      {service[category].map(
                                                          (item: any, index: any) => (
                                                              <Col sm={6} md={6} lg={3} key={index}>
                                                                  {_userData?.user_login ==
                                                                  'user' ? (
                                                                      <DeleteIcon
                                                                          onClick={() =>
                                                                              deleteModel(item._id)
                                                                          }>
                                                                          <i className="fal fa-trash-alt"></i>
                                                                          {isSale && isSale && (
                                                                              <i
                                                                                  className="fal fa-edit"
                                                                                  style={{
                                                                                      color: 'black',
                                                                                  }}
                                                                                  onClick={() =>
                                                                                      isEdit(item)
                                                                                  }></i>
                                                                          )}
                                                                      </DeleteIcon>
                                                                  ) : (
                                                                      ''
                                                                  )}

                                                                  <ServiceCard
                                                                      onClick={() => {
                                                                          if (
                                                                              _userData?.user_login ==
                                                                              'user'
                                                                          ) {
                                                                              selectService(item);
                                                                          }
                                                                      }}
                                                                      className={`${
                                                                          s.service
                                                                      } service-color-${
                                                                          item.color
                                                                      }--bg cursor-pointer
                                                      ${
                                                          isSale &&
                                                          selectedService?._id === item._id
                                                              ? s.services_selectedService
                                                              : ''
                                                      }
                                                      service-color-${item.color}--bd
                                                      `}>
                                                                      <PriceTag>
                                                                          <PriceField>
                                                                              &nbsp;
                                                                              {
                                                                                  _userData.user
                                                                                      .business_id
                                                                                      .currency
                                                                              }
                                                                              {item.price}
                                                                          </PriceField>
                                                                      </PriceTag>
                                                                      <CustomDiv>
                                                                          <ServiceName>
                                                                              {item.name}
                                                                          </ServiceName>
                                                                          <div>
                                                                              {item?.is_video && (
                                                                                  <i className="fal fa-video"></i>
                                                                              )}
                                                                          </div>
                                                                      </CustomDiv>
                                                                      <ServiceDescription>
                                                                          {item.description}
                                                                      </ServiceDescription>
                                                                      <Time>{`${
                                                                          item.duration >= 60
                                                                              ? Math.floor(
                                                                                    item.duration /
                                                                                        60
                                                                                ) + 'h'
                                                                              : ''
                                                                      } ${item.duration % 60}${t(
                                                                          'min'
                                                                      )}`}</Time>
                                                                  </ServiceCard>
                                                              </Col>
                                                          )
                                                      )}
                                                  </Row>
                                              </Wrapper>
                                          ))
                                        : loading === false && (
                                              <NotFound>{t('No Service Found')}</NotFound>
                                          )}
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {tabValue === 1 && (
                        <Row>
                            <HeaderCustom col={12} style={{ marginBottom: '1rem' }}>
                                <Heading>{t('Service Categories')}</Heading>
                                <HeaderText></HeaderText>
                                <Button
                                    label={t('Add service category')}
                                    bgtype="secondary"
                                    ifClicked={() => setCreateLabelService(true)}></Button>
                            </HeaderCustom>
                            <Col lg={12}>
                                <Card>
                                    <Wrapper>
                                        <Row
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '0.5rem',
                                            }}>
                                            {categories?.length
                                                ? categories.map((service, i) => (
                                                      <CategoryCard key={i} sm={6} md={6} lg={2}>
                                                          {_userData?.user_login == 'user' ? (
                                                              <DeleteIcon
                                                                  isSale={isSale}
                                                                  style={{
                                                                      background: 'transparent',
                                                                      width: 'auto',
                                                                  }}>
                                                                  <div
                                                                      style={{
                                                                          position: 'relative',
                                                                          display: 'flex',
                                                                          gap: '0.5rem',
                                                                      }}>
                                                                      <i
                                                                          className="fal fa-trash-alt"
                                                                          style={{ color: 'red' }}
                                                                          onClick={() => {
                                                                              deleteModel(
                                                                                  service._id
                                                                              );
                                                                          }}></i>
                                                                      <i
                                                                          className="fal fa-edit"
                                                                          style={{ color: 'black' }}
                                                                          onClick={() => {
                                                                              setEditCategory(
                                                                                  service
                                                                              );
                                                                              setCreateLabelService(
                                                                                  true
                                                                              );
                                                                          }}></i>
                                                                  </div>
                                                              </DeleteIcon>
                                                          ) : (
                                                              ''
                                                          )}

                                                          <ServiceCard
                                                              className={`${s.service} service-color-${service.color}--bg cursor-pointer `}>
                                                              <PriceTag></PriceTag>
                                                              <CustomDiv>
                                                                  <ServiceName>
                                                                      {service.name}
                                                                  </ServiceName>
                                                              </CustomDiv>
                                                          </ServiceCard>
                                                      </CategoryCard>
                                                  ))
                                                : loading === false && (
                                                      <NotFound>
                                                          {t('No Service Category Found')}
                                                      </NotFound>
                                                  )}
                                        </Row>
                                    </Wrapper>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {openDeleteModel && (
                        <DeleteModel
                            id={''}
                            onDelete={() => {
                                setOpenDeleteModel(false);
                            }}
                            onClose={() => {
                                setOpenDeleteModel(false);
                            }}
                            name={t(tabValue == 1 ? 'Category' : 'Service')}
                            content={t(
                                tabValue == 1
                                    ? 'If you delete this category you will lose all information connected to their category. This action cannot be undone.'
                                    : 'If you delete this service you will lose all information connected to their service including assigned staff service, appointment service. This action cannot be undone.'
                            )}
                            loading={loading}
                            onClick={() =>
                                tabValue == 1
                                    ? deleteCategoryService(deleteId)
                                    : deleteService(deleteId)
                            }
                        />
                    )}
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
    z-index: 0;
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
    cursor: pointer;
`;

const CategoryCard = styled(Col)`
    background: ${palette.white};
    box-shadow: inset -0.80118px -2.70178px 6.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
`;

const ServiceCard = styled.div`
    margin-bottom: 1.5rem;
`;

const PriceTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0px;
    // position: absolute;
    // top: 0.4rem;
    // right: 0.4rem;
`;

const ServiceName = styled.h1`
    font-weight: 500;
    font-size: 0.8rem;
    // width: 200px;
    white-space: pre-wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDescription = styled.h5`
    font-weight: 300;
    font-size: 0.8rem;
    width: 200px;
    white-space: pre-wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const Time = styled.div`
    font-size: 0.8rem !important;
`;

export const DeleteIcon = styled.div<any>`
    padding: 0;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${({ isSale }) => (isSale ? '' : palette.primary)};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0.4rem;
    left: 1.3rem;
    z-index: 1;
    &:active {
        border: none !important;
        outline: none;
    }
    &:focus {
        border: none !important;
        outline: none;
    }
`;

const PriceField = styled.div`
    background-color: ${palette.green};
    padding: 0.15rem 0.4rem 0.15rem 0.15rem;
    display: inline-block;
    border-radius: 2rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${palette.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
`;

const HeaderCustom = styled(Col)`
    display: flex;
    justify-content: space-between;
`;
const ContainerCustom = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const HeaderText = styled.div`
    ${media.xs`
	display: flex;
  `}
    ${media.sm`
	display: flex;
  `}
	${media.md`
	display: flex;
  `}
	${media.lg`
	display: none;
  `}
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
    ${media.xs`
	display: none;
  `}
    ${media.sm`
	display: none;
  `}
	${media.md`
	display: none;
  `}
	${media.lg`
	display: flex;
  `}
`;

export const CustomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TabsWrapper = styled.div`
    color: #8c8b88;
    background-color: #fafafa;
    min-height: 36px;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: stretch;
    cursor: pointer;
    position: relative;
    font-size: 13px;
    justify-content: space-evenly;
    margin-bottom: 1rem;
`;

const Tab = styled(Col)<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9rem;
    border-radius: 0.2rem;
    font-weight: 500;
    background-color: ${({ active }) => (active ? palette.secondary : '')};
    color: ${({ active }) => (active ? palette.light : '')};
`;

const CategoryTitle = styled.h2`
    margin-bottom: 0.5rem !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

export default BusinessServices;
