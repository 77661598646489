import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from '../../../styled/common';
import { FiChevronDown, FiChevronUp, FiEdit, FiTrash } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

interface PackageCardProps {
    pkg: Package;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    currency: string;
    isEditable?: boolean;
    isSelectable?: boolean;
    onClick?: () => void;
}

interface Package {
    _id: string;
    name: string;
    price: string;
    valid_until: string | null;
    show_on_widget: boolean;
    available_by_call: boolean;
    total_sold: number;
    services: Service[];
    services_details: ServiceGroup[];
    label_id: string;
}

interface ServiceGroup {
    total_visits: number;
    services: Service[];
}

interface Service {
    service_id: string;
    name: string;
    price: string;
    duration: string;
}

const PackageCard: React.FC<PackageCardProps> = ({
    pkg,
    onEdit,
    onDelete,
    currency,
    isEditable = true,
    isSelectable = false,
    onClick,
}) => {
    const [expanded, setExpanded] = useState(false);
    const { t }: any = useTranslation();

    const formatDate = (date: string | null) => {
        if (!date) return t('Not specified');
        const parsedDate = new Date(date);
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${day}.${month}.${year}`;
    };

    return (
        <Card isSelectable={isSelectable} onClick={onClick} isInactive={!pkg.show_on_widget}>
            <CardHeader>
                <Title>{pkg.name}</Title>
                <Price>
                    {currency}
                    {pkg.price}
                </Price>
                <IconsContainer>
                    <FiEdit size={18} onClick={() => onEdit(pkg._id)} />
                    <FiTrash size={18} onClick={() => onDelete(pkg._id)} />
                </IconsContainer>
            </CardHeader>
            <CompactView>
                <Summary>
                    {t('Visits')}{' '}
                    {pkg.services_details.reduce((sum, group) => sum + group.total_visits, 0)} |
                    {t('Sold')}: {pkg.total_sold}
                </Summary>
                <ChevronContainer onClick={() => setExpanded(!expanded)}>
                    {expanded ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
                    <Tooltip>{expanded ? t('Collapse') : t('Expand')}</Tooltip>
                </ChevronContainer>
            </CompactView>
            {expanded && (
                <ExpandedServices>
                    {pkg.services_details.map((group, index) => (
                        <div key={index}>
                            <ServiceGroupContainer>
                                {group.services.map(service => (
                                    <ServiceItem key={service.service_id}>
                                        <ServiceName>{service.name}</ServiceName>
                                        <ServiceDetails>
                                            {t('Price')}: {currency}
                                            {service.price}, {t('Duration')}: {service.duration}{' '}
                                            {t('min')}
                                        </ServiceDetails>
                                    </ServiceItem>
                                ))}
                                <TotalVisits>
                                    {t('Visits in Package')}: {group.total_visits}
                                </TotalVisits>
                            </ServiceGroupContainer>
                            {index < pkg.services_details.length - 1 && <Divider />}
                        </div>
                    ))}
                </ExpandedServices>
            )}
            <PackageDetails>
                <DetailItem>
                    <DetailLabel>{t('Valid to')}</DetailLabel>
                    <DetailValue>{formatDate(pkg.valid_until)}</DetailValue>
                </DetailItem>
                <DetailItem>
                    <DetailLabel>{t('Available')}</DetailLabel>
                    <DetailValue>{pkg.show_on_widget ? t('Yes') : t('No')}</DetailValue>
                </DetailItem>
                <DetailItem>
                    <DetailLabel>{t('Available by phone')}</DetailLabel>
                    <DetailValue>{pkg.available_by_call ? t('Yes') : t('No')}</DetailValue>
                </DetailItem>
            </PackageDetails>
        </Card>
    );
};

// Styled Components
const Card = styled.div<{ isSelectable?: boolean; isInactive?: boolean }>`
    background: ${({ isInactive }) =>
        isInactive
            ? 'linear-gradient(135deg, #b0b0b0, #dcdcdc, #f5f5f5)'
            : 'linear-gradient(135deg, #cfd9ff, #fbc2eb, #fceabb)'};

    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'default')};
    opacity: ${({ isInactive }) =>
        isInactive ? '0.7' : '1'}; // Чуть приглушенный цвет для неактивных карточек

    &:hover {
        transform: ${({ isSelectable }) => (isSelectable ? 'translateY(-5px)' : 'none')};
        box-shadow: ${({ isSelectable }) =>
            isSelectable ? '0 10px 18px rgba(0, 0, 0, 0.2)' : 'none'};
        filter: ${({ isSelectable }) => (isSelectable ? 'brightness(1.05)' : 'none')};
    }
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Title = styled.h3`
    font-size: 1.2rem;
    font-weight: bold;
    color: ${palette.grey.dark};
    margin: 0;
`;

const Price = styled.span`
    font-size: 1rem;
    color: ${palette.primary};
    font-weight: bold;
`;

const CompactView = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Summary = styled.div`
    font-size: 0.9rem;
    color: ${palette.grey.dark};
`;

const ChevronContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${palette.primary};
    position: relative;
    &:hover {
        opacity: 0.8;
    }
`;

const Tooltip = styled.div`
    font-size: 0.75rem;
    background: ${palette.grey.dark};
    color: ${palette.white};
    padding: 4px 8px;
    border-radius: 6px;
    position: absolute;
    bottom: -25px;
    left: -20px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    ${ChevronContainer}:hover & {
        visibility: visible;
        opacity: 1;
    }
`;

const ExpandedServices = styled.div`
    margin-top: 10px;
`;

const ServiceGroupContainer = styled.div`
    margin-bottom: 12px;
`;

const ServiceItem = styled.div`
    margin-bottom: 6px;
`;

const ServiceName = styled.div`
    font-weight: bold;
    color: ${palette.grey.dark};
`;

const ServiceDetails = styled.div`
    font-size: 0.875rem;
    color: ${palette.grey};
`;

const TotalVisits = styled.div`
    font-size: 0.875rem;
    font-weight: bold;
    color: ${palette.primary};
`;

const Divider = styled.div`
    height: 1px;
    background: linear-gradient(
        to right,
        ${palette.grey.light} 0%,
        ${palette.grey.dark} 50%,
        ${palette.grey.light} 100%
    );
    margin: 16px 0;
    border: none;
`;

const PackageDetails = styled.div`
    margin-top: 10px;
`;

const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const DetailLabel = styled.span`
    font-weight: bold;
    color: ${palette.grey.dark};
`;

const DetailValue = styled.span`
    color: ${palette.grey};
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 8px;
`;

const ActionButton = styled.button<{ delete?: boolean }>`
    color: ${palette.white};
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background: ${({ delete: isDelete }) => (isDelete ? palette.primary : palette.secondary)};
    transition: background 0.2s ease;
    &:hover {
        background: ${({ delete: isDelete }) => (isDelete ? palette.primary : palette.secondary)};
    }
`;

const Category = styled.div`
    font-size: 0.9rem;
    color: ${palette.grey.dark};
    margin-bottom: 8px;
`;

const IconsContainer = styled.div`
    display: flex;
    gap: 8px;
    cursor: pointer;
    color: ${palette.grey.dark};
    &:hover {
        color: ${palette.primary};
    }
`;

export default PackageCard;
