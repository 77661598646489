/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DeleteModel Component
 * Description: This component represents a modal for deleting a record. It includes a confirmation message, buttons for delete and cancel actions, and uses styled components for styling.
 *
 * Props:
 * - id?: string - The ID of the record to be deleted.
 * - onClose(): void - Callback function triggered when the modal is closed.
 * - onDelete(): void - Callback function triggered when the delete button is clicked.
 * - onCLick(): void - Callback function triggered when a click event occurs (intended to be used for additional functionality).
 * - loading: any - Loading state to determine whether to disable the delete button during loading.
 * - name: string - The name or title of the record being deleted.
 * - content: string - The content or message displayed in the modal.
 *
 * Purpose:
 * - Renders a modal for confirming deletion with the item's name and content
 * - Utilizes styled-components for styling and theming
 * - Responsive design for various screen sizes
 * - Handles click events for delete and cancel actions
 * - Displays loading state and disables buttons during loading
 *
 */

import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';

export interface IDeleteModel {
    id?: string;
    onClose(): void;
    onDelete?: () => void;
    onClick?: () => void;
    loading: any;
    name: string;
    content: string;
    isAdmin?: any;
    buttonTitle?: any;
}

const DeleteModel = ({
    loading,
    name,
    onClose,
    content,
    onDelete,
    onClick,
    isAdmin,
    buttonTitle,
}: IDeleteModel) => {
    const { t }: any = useTranslation();

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            <Container>
                <Model tabIndex={-1} onClick={e => !loading && closeModal(e)}>
                    <ModelBody lg={12}>
                        <ModelTitle>
                            <CustomDiv>
                                <ModelHeading>
                                    {' '}
                                    {isAdmin ? '' : t('Delete')} {name}?
                                </ModelHeading>
                            </CustomDiv>
                            <ModelClose>
                                <CloseButton onClick={() => !loading && onClose()}>
                                    {' '}
                                    <i className="fal fa-times"></i>
                                </CloseButton>
                            </ModelClose>
                        </ModelTitle>

                        <ModelContent>{content}</ModelContent>
                        <ModelButtons>
                            <Button
                                ifClicked={onClick}
                                bgtype={'primary'}
                                label={buttonTitle ? buttonTitle : t('Delete')}
                                width="13rem !important"></Button>
                            &nbsp;&nbsp;
                            <Button
                                ifClicked={!loading && onClose}
                                bgtype={'discard'}
                                label={t('Cancel')}
                                width="13rem !important"></Button>
                        </ModelButtons>
                    </ModelBody>
                </Model>
            </Container>
        </>
    );
};

const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    border-radius: 0.6rem;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 1.7rem;
    padding-top: 0;
    max-width: 35vw;
    ${media.xs`
        width: 100%;
        max-width: 100%;
    `}
    ${media.sm`
        width: 100%;
        max-width: 60vw;
    `}
    ${media.md`
        width: 100%;
        max-width: 45vw;
    `}
    ${media.lg`
        max-width: 35vw;
    `}
`;

const CustomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModelClose = styled.div`
    display: flex;
    justify-content: end;
`;

const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-size: 1.7rem;
    border: none;
    cursor: pointer;
`;

const ModelTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
`;

const ModelHeading = styled.div`
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
     font-size: 1.7rem;
    `}
`;

const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-top: 1.25rem !important;
    padding-bottom: 2.5rem !important;
    color: rgba(157, 160, 164);
`;

const ModelButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default DeleteModel;
