import { useState, useEffect } from 'react';
import { Spinner } from '@components/common';
import { ICustomerPackage } from 'interfaces/cuastomerPackage.interface';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { NotFound } from '@components/team/list/serviceProviderList/ServiceProviderList';
import moment from 'moment';
import { palette } from 'styled/common';
import { Link } from 'react-router-dom';

const CustomerPackages = ({ customerId }: { customerId?: any }) => {
    const { t } = useTranslation();
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [customerPackages, setCustomerPackages] = useState<ICustomerPackage[]>([]);
    const [customerAppointments, setCustomerAppointments] = useState<any[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<ICustomerPackage | null>(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [expandedPackage, setExpandedPackage] = useState<string | null>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        console.log('✅ Загруженные записи (customerAppointments):', customerAppointments);
    }, [customerAppointments]);

    useEffect(() => {
        if (customerId) {
            setLoading(true);
            customerPackageDetails();
            setPage(0);
            fetchCustomerAppointments();
        }
    }, [customerId]);

    const customerPackageDetails = async () => {
        const customer_id = typeof customerId === 'string' ? customerId : customerId?._id;
        if (!customer_id) {
            console.error(
                '❌ Ошибка: customerId не передан или имеет неверный формат.',
                customerId
            );
            return;
        }
        setLoading(true);
        try {
            console.log(`🔍 Загружаем пакеты для клиента: ${customer_id}`);
            const response = await api.get<ICustomerPackage[]>(
                `/customer-package-service?customer_id=${customer_id}`
            );
            setLoading(false);
            if (Array.isArray(response.data)) {
                setCustomerPackages(response.data);
            } else {
                console.error('⚠️ Ошибка: API вернул неожиданный формат данных', response.data);
                setCustomerPackages([]);
            }
        } catch (e: any) {
            setLoading(false);
            console.error('❌ Ошибка загрузки пакетов:', e.response?.data?.message || e.message);
        }
    };
    const fetchCustomerAppointments = async () => {
        if (!customerId) return;

        const customer_id = typeof customerId === 'string' ? customerId : customerId?._id;
        if (!customer_id) {
            console.error(
                '❌ Ошибка: customerId отсутствует или имеет неверный формат',
                customerId
            );
            return;
        }

        setLoading(true);
        try {
            console.log('🔍 Запрашиваем встречи для клиента:', customer_id);
            const response = await api.get<any>(
                `/appointments/customers/${customer_id}?page=${0}&pageSize=${pageSize}`
            );

            // ⚠️ Извлекаем только bookings
            console.log('✅ Полученные записи клиента:', response.data);
            setCustomerAppointments(response.data.bookings || []);
        } catch (e: any) {
            console.error('❌ Ошибка загрузки записей:', e.response?.data?.message || e.message);
            setCustomerAppointments([]);
        } finally {
            setLoading(false);
        }
    };

    const togglePackage = (packageId: string) => {
        setExpandedPackage(prev => (prev === packageId ? null : packageId));
    };

    const handleCancelPackage = async () => {
        if (!selectedPackage) return;
        try {
            await api.post(`/customer-package-service/cancel`, {
                package_id: selectedPackage._id.toString(),
            });
            setShowCancelModal(false);
            setCustomerPackages(prevPackages =>
                prevPackages.map(pkg =>
                    pkg._id === selectedPackage._id ? { ...pkg, remaining_visits: 0 } : pkg
                )
            );
        } catch (error) {
            console.error('Ошибка при отмене пакета', error);
        }
    };

    const handleDeletePackage = async () => {
        if (!selectedPackage) return;
        try {
            await api.delete(`/customer-package-service/${selectedPackage._id}`);
            setShowDeleteModal(false);
            setCustomerPackages(prevPackages =>
                prevPackages.filter(pkg => pkg._id !== selectedPackage._id)
            );
        } catch (error) {
            console.error('Ошибка при удалении пакета', error);
        }
    };

    return (
        <Wrapper>
            <Row>
                {customerPackages.length > 0
                    ? customerPackages.map((packageData: any, i: number) => {
                          const packageAppointments =
                              customerAppointments?.filter(
                                  app => app.package_id === packageData._id
                              ) || [];
                          return (
                              <PackageContainer key={i}>
                                  <PackageHeader>
                                      <StatusWrapper>
                                          <PaymentStatus
                                              status={
                                                  packageData.is_cancelled
                                                      ? 'Cancelled'
                                                      : packageData.remaining_visits > 0
                                                      ? 'Active'
                                                      : 'Used'
                                              }>
                                              {packageData.is_cancelled
                                                  ? t('Cancelled')
                                                  : packageData.remaining_visits > 0
                                                  ? t('Active')
                                                  : t('Used')}
                                          </PaymentStatus>
                                      </StatusWrapper>
                                      <PackageTitle>
                                          {packageData?.package_id?.name || t('Unknown Package')}
                                      </PackageTitle>
                                      {packageAppointments.length > 0 && (
                                          <ExpandButton
                                              onClick={() => togglePackage(packageData._id)}>
                                              {expandedPackage === packageData._id
                                                  ? t('Hide Appointments')
                                                  : t('Show Appointments')}
                                          </ExpandButton>
                                      )}
                                  </PackageHeader>
                                  <PackageContent>
                                      <PackageInfo>
                                          {t('Purchase Date')}:{' '}
                                          {moment(packageData.purchase_date).format('YYYY-MM-DD')}
                                      </PackageInfo>
                                      <PackageInfo>
                                          {t('Valid Until')}:{' '}
                                          {moment(packageData.valid_until).format('YYYY-MM-DD')}
                                      </PackageInfo>

                                      {/* Список встреч внутри пакета */}
                                      {expandedPackage === packageData._id &&
                                          packageAppointments.length > 0 && (
                                              <AppointmentsList>
                                                  {packageAppointments.map((appointment, index) => (
                                                      <CustomLink
                                                          key={index}
                                                          to={
                                                              _userData?.user_login === 'customer'
                                                                  ? `/${_userData?.user?.business_id?._id}/${appointment._id}/appointment`
                                                                  : `/calendar?appointment=${appointment._id}`
                                                          }>
                                                          <AppointmentItem>
                                                              <span>
                                                                  {moment(
                                                                      appointment.booked_from
                                                                  ).format('DD.MM.YY HH:mm')}
                                                              </span>
                                                              -{' '}
                                                              <span>
                                                                  {appointment.service?.name}
                                                              </span>
                                                              <StatusLabel
                                                                  status={appointment.status}>
                                                                  {t(appointment.status)}
                                                              </StatusLabel>
                                                          </AppointmentItem>
                                                      </CustomLink>
                                                  ))}
                                              </AppointmentsList>
                                          )}

                                      {packageData?.services_groups_usage?.map(
                                          (group: any, index: number) => (
                                              <div key={index}>
                                                  <Separator />
                                                  <ServiceGroupHeader>
                                                      {t('Services in Package')} ({t('Total')}:{' '}
                                                      {group.total_visits}, {t('Remaining')}:{' '}
                                                      {group.remaining_visits}, {t('Used')}:{' '}
                                                      {group.total_visits - group.remaining_visits})
                                                  </ServiceGroupHeader>
                                                  <ServiceList>
                                                      {group.services.map(
                                                          (service: any, idx: number) => (
                                                              <ServiceItem key={idx}>
                                                                  <ServiceName>
                                                                      {service.name}
                                                                  </ServiceName>
                                                                  <ServiceDetails>
                                                                      ({t('Price')}: {service.price}
                                                                      , {t('Duration')}:{' '}
                                                                      {service.duration}{' '}
                                                                      {t('minutes')})
                                                                  </ServiceDetails>
                                                              </ServiceItem>
                                                          )
                                                      )}
                                                  </ServiceList>
                                              </div>
                                          )
                                      )}
                                  </PackageContent>
                                  <PackageFooter>
                                      <ActionButtonsWrapper>
                                          <CancelButton
                                              onClick={() => {
                                                  setSelectedPackage(packageData);
                                                  setShowCancelModal(true);
                                              }}>
                                              {t('Cancel')}
                                          </CancelButton>
                                          <DeleteButton
                                              onClick={() => {
                                                  setSelectedPackage(packageData);
                                                  setShowDeleteModal(true);
                                              }}>
                                              {t('Delete')}
                                          </DeleteButton>
                                      </ActionButtonsWrapper>
                                  </PackageFooter>
                              </PackageContainer>
                          );
                      })
                    : !loading && <NotFound>{t('No package found')}</NotFound>}

                {/* Кнопка "Load More" */}
                {(total > (page + 1) * pageSize || loading) && (
                    <PaymentPages>
                        <Button
                            disabled={loading}
                            onClick={() => setPage(prevPage => prevPage + 1)}>
                            {loading ? (
                                <Spinner color="secondary" />
                            ) : (
                                `${t('Load more packages')} (${total - (page + 1) * pageSize})`
                            )}
                        </Button>
                    </PaymentPages>
                )}
            </Row>

            {/* Модальное окно отмены */}
            {showCancelModal && (
                <ModalOverlay>
                    <ModalContent>
                        <h3>{t('Confirm Cancellation')}</h3>
                        <p>{t('Are you sure you want to cancel this package?')}</p>
                        <ModalActions>
                            <ConfirmButton onClick={handleCancelPackage}>
                                {t('Yes, Cancel')}
                            </ConfirmButton>
                            <CancelButton onClick={() => setShowCancelModal(false)}>
                                {t('No, Keep')}
                            </CancelButton>
                        </ModalActions>
                    </ModalContent>
                </ModalOverlay>
            )}

            {/* Модальное окно удаления */}
            {showDeleteModal && (
                <ModalOverlay>
                    <ModalContent>
                        <h3>{t('Confirm Deletion')}</h3>
                        <p>
                            {t(
                                'Are you sure you want to delete this package? This action cannot be undone.'
                            )}
                        </p>
                        <ModalActions>
                            <DeleteConfirmButton onClick={handleDeletePackage}>
                                {t('Yes, Delete')}
                            </DeleteConfirmButton>
                            <CancelButton onClick={() => setShowDeleteModal(false)}>
                                {t('No, Keep')}
                            </CancelButton>
                        </ModalActions>
                    </ModalContent>
                </ModalOverlay>
            )}
        </Wrapper>
    );
};

// Стили
const ServiceName = styled.span`
    font-weight: 500;
`;
const ServiceDetails = styled.span`
    opacity: 0.8;
    font-size: 0.8rem;
`;

const Wrapper = styled(Container)`
    display: flex;
    justify-content: center; /* 🔥 Центрируем */
`;

const CustomLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%; /* 🔥 Вся строка записи кликабельна */
    &:hover {
        background-color: rgba(0, 0, 0, 0.05); /* Лёгкий эффект при наведении */
    }
`;

const PackageTitle = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
`;
const PackageInfo = styled.div`
    opacity: 0.8;
    font-size: 0.85rem;
`;
const ServiceGroupHeader = styled.div`
    font-weight: bold;
    margin-top: 10px;
`;
const ServiceList = styled.div`
    margin-top: 5px;
`;
const ServiceItem = styled.div`
    display: flex;
    gap: 0.3rem;
`;
const Separator = styled.hr`
    margin: 10px 0;
    border: 0;
    height: 1px;
    background: gray;
`;
const PaymentPages = styled.div`
    display: flex;
    justify-content: center;
`;
const Button = styled.button`
    padding: 0.5rem 2rem;
    font-weight: 500;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
`;

const ButtonBase = styled.button`
    min-width: 120px;
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: background 0.2s ease-in-out;
`;

const ExpandButton = styled.button`
    background: linear-gradient(135deg, #007aff 0%, #0056b3 100%);
    color: white;
    padding: 0.6rem 1.2rem;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 500;
    font-size: 0.9rem;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
        background: linear-gradient(135deg, #0056b3 0%, #003d82 100%);
        transform: scale(1.05);
    }
`;

const ConfirmButton = styled(ButtonBase)`
    background-color: #27ae60;
    color: white;

    &:hover {
        background-color: #219150;
    }
`;

const DeleteConfirmButton = styled(ButtonBase)`
    background-color: #d9534f;
    color: white;

    &:hover {
        background-color: #c9302c;
    }
`;

const AppointmentsList = styled.div`
    margin-top: 10px;
    background: #f8f9fa;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const AppointmentItem = styled.div`
    display: flex;
    justify-content: space-between;
    background: rgba(240, 240, 240, 0.7);
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: 500;
    width: 100%;
    min-width: 300px;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: rgba(220, 220, 220, 0.7);
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background: white;
    padding: 25px;
    border-radius: 8px;
    width: 350px;
    text-align: center;
`;

const ModalActions = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
`;

const StatusLabel = styled.span<{ status: string }>`
    font-weight: 600;
    color: ${({ status }) =>
        status === 'confirmed' ? '#007aff' : status === 'cancelled' ? '#ff3b30' : '#8e8e93'};
    background: ${({ status }) =>
        status === 'confirmed'
            ? 'rgba(0, 122, 255, 0.1)'
            : status === 'cancelled'
            ? 'rgba(255, 59, 48, 0.1)'
            : 'rgba(142, 142, 147, 0.1)'};
    padding: 4px 10px;
    border-radius: 6px;
`;

const PackageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    margin: 1rem auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const PackageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* 🔥 Растягиваем */
`;

const PackageContent = styled.div`
    padding: 1rem 0;
    width: 100%;
`;

const PackageFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
`;

const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const PaymentStatus = styled.div<{ status: string }>`
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-weight: 400;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: ${({ status }) =>
        status === 'Cancelled' ? '#ff3b30' : status === 'Used' ? '#8e8e93' : '#27ae60'};

    background-color: ${({ status }) =>
        status === 'Cancelled' ? '#ffe5e5' : status === 'Used' ? '#f0f0f5' : '#e2fff3'};
`;

const CancelButton = styled.button`
    background-color: #ff9900;
    color: white;
    padding: 0.6rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: background 0.2s ease-in-out;

    &:hover {
        background-color: #e68a00;
    }
`;

const DeleteButton = styled.button`
    background-color: #c0392b;
    color: white;
    padding: 0.6rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: background 0.2s ease-in-out;

    &:hover {
        background-color: #a93226;
    }
`;

export default CustomerPackages;
