/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Common defined colour used in app.
 * defined default font sizes also to use commenly in app.
 */

export const palette = {
    primary: '#eb5353', // delete red
    // secondary: '#092C4C', // buttons
    secondary: '#000',
    success: '#14a88b',
    black: '#000',
    white: '#fff',
    dark: '#27292e ', // normal text
    light: '#ffffff',
    silver: '#7d8083', // para text color or icon
    gold: '#B99F57',
    teal: '#00E2BA',
    green: '#00be70', //price tags
    danger: 'ec5353',
    freshGreen: '#16a34a',
    simpleGreen: 'green', //for service provider status
    hover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #092C4C;', //for buttons hover effect
    // header: 'linear-gradient(350.99deg, #333333 -62.86%, #092c4c 125.32%)', //for navbar. sidebar
    header: '#91a2eb',
    overlay: '#00000080', // models side overlay
    grey: {
        lightest: '#e5e7eb', //border
        light: '#f4f5f6', //page gray
        shadow: '#523f690d', //card shadow
        shadowDarkest: '#00000047', // + button shadow
        dark: '#636363',
        darkest: '#232323',
        dark_50: '#7e8299',
        grey_20: 'rgba(157, 160, 164)',
        grey_60: '#7c7c7c;',
        grey_80: '#3f4254',
    },
    blue: {
        defaultBlue: '#2f80ed',
        inkBlue: '#4f45e4',
        stripe_button: '#635bff',
    },
    dashboard_card_shadow: 'rgba(0, 0, 0, 0.25)',
    customer_drawer_shadow: 'rgb(62 53 71 / 18%)',
    select_box: '#d1d5db', // for appointment drawer select service provider/customer/service
    red_10: '#f1416c',
    box_hadow_color: 'rgba(0, 0, 0, 0.05)', // for box shadow
    modal_background: 'rgba(0, 0, 0, 0.5)', // for popup models header background
    toast_notification_background: 'black', // for notificaition bar background
};
export const fonts = {
    xsmall: '0.8rem',
    small: '1rem',
    regular: '1.2rem',
    medium: '1.8rem',
    large: '2.2rem',
    xlarge: '3.0rem',
    xxlarge: '4rem',
};
