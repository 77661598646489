/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: AsideMobile
 * Description: This component represents the mobile version of the aside menu.
 * Renders the aside navigation list based on user permissions and roles on mobile view.
 *
 * Dependencies:
 * - React
 * - styled-components
 * - styled-bootstrap-grid (Container, Col, media)
 * - @components/common (Drawer)
 * - constants/routes
 * - EditProfileModel
 *
 * Usage:
 * - Import this component to display the mobile aside menu.
 */

import { Drawer } from '@components/common';
import BrandInfo from '../aside/BrandInfo';
import AsideList from '../aside/AsideList';
import Profile from '../aside/Profile';
import { useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import { routes } from 'constants/routes';
import { palette } from 'styled/common';
import { useNavigate } from 'react-router-dom';
import EditProfileModel from '../EditProfileModel';
import { useTranslation } from 'react-i18next';

interface Properties {
    open?: boolean;
    openAsidNew?: any;
    user?: any;
}

const AsideMobile = ({ open, openAsidNew, user }: Properties) => {
    let _user = JSON.parse(localStorage.getItem('user') || '{}');
    const [editProfileModal, setEditProfileModel] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const doLogout = () => {
        _user?.user_login == 'customer'
            ? navigate(`/${_user?.user?.business_id?._id}/login`)
            : navigate(`/login`);
        localStorage.removeItem('user');
        localStorage.removeItem('url');
        // window.Intercom('shutdown');
    };

    return (
        <>
            <MainWrapper>
                <Drawer custom={true} left={true} active={open}>
                    <Row>
                        <AppointmentContent>
                            <HeaderContainer>
                                <BrandInfo />

                                <CloseButton onClick={openAsidNew}>
                                    <Icon className="fal fa-times"></Icon>
                                </CloseButton>
                            </HeaderContainer>
                            <DrawerBody>
                                <Wrapper>
                                    <Row>
                                        <List lg={12}>
                                            <AsideList routes={routes} openAsidNew={openAsidNew} />
                                            <CustomCol
                                                col={12}
                                                onClick={() => {
                                                    import(
                                                        '../../../services/push-notifications'
                                                    ).then(({ registerServiceWorker }) => {
                                                        registerServiceWorker();
                                                    });
                                                }}>
                                                <i className="fal fa-bell"></i>
                                                {t('Enable Notifications')}
                                            </CustomCol>
                                            <CustomCol col={12} onClick={() => doLogout()}>
                                                {' '}
                                                <i className="far fa-sign-out"></i>
                                                {t('Logout')}
                                            </CustomCol>
                                        </List>
                                    </Row>
                                </Wrapper>
                            </DrawerBody>
                            <Wrapper>
                                <Profile onClick={() => setEditProfileModel(true)} />
                            </Wrapper>
                        </AppointmentContent>
                    </Row>
                </Drawer>
            </MainWrapper>
            {editProfileModal && <EditProfileModel onClose={() => setEditProfileModel(false)} />}
        </>
    );
};

const AppointmentContent = styled(Col)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: ${palette.header};
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1rem 1rem 0.5rem;
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
`;

const CloseButton = styled.div`
    margin-left: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: ${palette.light};
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem;
    padding: 0 1.75rem;
    overflow: scroll;
`;

const MainWrapper = styled(Container)`
    ${media.xs`
    display: flex;
  `}
    ${media.sm`
    display: flex;
  `}
    ${media.md`
	display: flex;
  `}
    ${media.lg`
    display:none;
  `}
`;

const Wrapper = styled(Container)`
    padding: 0;
`;

const List = styled(Col)`
    width: 100%;
    flex: 1 1 100%;
    padding: 0rem;
    margin: 0rem;
`;

const CustomCol = styled(Col)`
    padding: 1rem;
    cursor: pointer;
    margin-left: 1rem;
    color: ${palette.light};
    display: flex;
    font-size: 1rem;
    align-items: center;
    gap: 1rem;
    ${media.xs`
   display: flex;
  `}
    ${media.sm`
    display: flex;
  `}
    ${media.md`
    display: flex;
  `}
    ${media.lg`
    display: none;
    `}
`;

export default AsideMobile;
