/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: NewPackageDrawer
 * Description: This component represents the drawer for purchasing a service package for a specific customer.
 * It allows selecting a customer, viewing package details, and confirming the purchase.
 * Developed with React and utilizes styled-components for styling.
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoaderOverlay, useSnackbar, Input } from '@components/common';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import SelectClient from './SelectClient';
import Button from '@components/Button';
import { ICustomer } from 'interfaces/customer.interface';
import { palette } from 'styled/common';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import logo from '../../../images/userDefault.png';

let _userData = JSON.parse(localStorage.getItem('user') || '{}');

const NewPackageDrawer = ({
    selectedPackage,
    onClose,
}: {
    selectedPackage?: any;
    onClose(): void;
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [customerView, setCustomerView] = useState(false);
    const [customerNote, setCustomerNote] = useState<string>('');

    const resetState = () => {
        setSelectedCustomer(null);
        setCustomerView(false);
        setCustomerNote('');
    };

    const handlePurchase = async () => {
        if (!selectedCustomer) {
            openSnackbar(t('Please select a customer!'));
            return;
        }

        setLoading(true);
        try {
            const packageDetails = selectedPackage;
            const requestData = {
                business_id: _userData?.user?.business_id?._id,
                customer_id: selectedCustomer._id,
                package_id: packageDetails?._id,
                purchase_date: new Date(),
                valid_until: packageDetails.valid_until || null,
                total_visits: packageDetails.services_details.reduce(
                    (sum: any, group: any) => sum + group.total_visits,
                    0
                ),
                remaining_visits: packageDetails.services_details.reduce(
                    (sum: any, group: any) => sum + group.total_visits,
                    0
                ),
                services_groups_usage: packageDetails.services_details.map((group: any) => ({
                    group_id: group._id,
                    total_visits: group.total_visits,
                    remaining_visits: group.total_visits,
                    services: group.services.map((service: any) => ({
                        service_id: service.service_id,
                        name: service.name,
                        price: service.price,
                        duration: service.duration,
                        used_count: 0,
                    })),
                })),
                appointment_ids: [],
                is_cancelled: false,
                package_note: customerNote || null,
            };
            await api.post('/customer-package-service/create', requestData);
            openSnackbar(t('Package purchased successfully!'));
            resetState();
            onClose();
        } catch (error) {
            openSnackbar(t('Failed to purchase package!'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    <Col style={{ zIndex: '1' }}>
                        <DrawerModel>
                            {!customerView && (
                                <AppointmentContent>
                                    <HeaderContainer>
                                        <Header>{t('Sale Package')}</Header>
                                    </HeaderContainer>
                                    <DrawerBody>
                                        {selectedCustomer ? (
                                            <div>
                                                <SelectCustomer>
                                                    <div
                                                        style={{ display: 'flex' }}
                                                        onClick={() => {
                                                            _userData?.user_login !== 'customer' &&
                                                                !loading &&
                                                                setCustomerView(true);
                                                        }}>
                                                        <Circle>
                                                            <NameCircle
                                                                src={`${process.env.REACT_APP_PROFILE_URL}${selectedCustomer.photo}`}></NameCircle>
                                                        </Circle>
                                                        <CustomerContent>
                                                            <Name>
                                                                {selectedCustomer.firstname +
                                                                    ' ' +
                                                                    selectedCustomer.lastname}
                                                            </Name>
                                                            <Email>{selectedCustomer.phone}</Email>
                                                            <Email>{selectedCustomer.email}</Email>
                                                        </CustomerContent>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}>
                                                        <Icons
                                                            className="fal fa-chevron-right"
                                                            onClick={() => {
                                                                _userData?.user_login !==
                                                                    'customer' &&
                                                                    setCustomerView(true);
                                                            }}></Icons>
                                                    </div>
                                                </SelectCustomer>
                                            </div>
                                        ) : (
                                            <div>
                                                <AppointmentCustomer>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() => {
                                                            _userData?.user_login !== 'customer' &&
                                                                !loading &&
                                                                setCustomerView(true);
                                                        }}>
                                                        <Circle>
                                                            <NameCircle
                                                                src={logo}
                                                                alt="logo"></NameCircle>
                                                        </Circle>
                                                        <Label>{t('Select a client')}</Label>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}></div>
                                                </AppointmentCustomer>
                                            </div>
                                        )}

                                        {
                                            <>
                                                {/* Рендер деталей пакета */}
                                                <SelectServiceContent>
                                                    <ServiceBorder
                                                        className={`service-color-${selectedPackage.color}--bd`}></ServiceBorder>
                                                    <ServiceDetails>
                                                        <NameBody>
                                                            <ServiceName>
                                                                {selectedPackage.name}
                                                            </ServiceName>
                                                            <ServiceDuration>
                                                                {selectedPackage.duration}
                                                            </ServiceDuration>
                                                        </NameBody>
                                                        <PriceBody>
                                                            <ServicePrice>
                                                                {
                                                                    _userData?.user?.business_id
                                                                        ?.currency
                                                                }
                                                                {selectedPackage.price}
                                                            </ServicePrice>
                                                        </PriceBody>
                                                    </ServiceDetails>
                                                </SelectServiceContent>
                                            </>
                                        }
                                        <FormContent>
                                            <FormDetails>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Package Notes') as string}
                                                        value={customerNote}
                                                        onChange={({
                                                            value,
                                                        }: {
                                                            value: string;
                                                        }) => {
                                                            setCustomerNote(value);
                                                        }}
                                                    />
                                                </InputGroup>

                                                <FormFooter>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}>
                                                        {' '}
                                                        <Button
                                                            bgtype={'secondary'}
                                                            label={t('Reserve')}
                                                            ifClicked={handlePurchase}
                                                            disabled={!selectedCustomer}
                                                        />
                                                    </div>
                                                </FormFooter>
                                            </FormDetails>
                                        </FormContent>
                                    </DrawerBody>
                                </AppointmentContent>
                            )}
                            {customerView && (
                                <SelectClient
                                    onClose={() => {
                                        setCustomerView(false);
                                    }}
                                    onSelect={(customer: ICustomer) => {
                                        setSelectedCustomer(customer);
                                    }}
                                />
                            )}
                        </DrawerModel>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

const PackageInfo = styled.div`
    padding: 10px;
    background: ${palette.grey.light};
    border-radius: 5px;
    margin-bottom: 20px;
`;

const PackageName = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: ${palette.white};
`;

const PackagePrice = styled.div`
    font-size: 16px;
    color: ${palette.grey.dark_50};
`;

const MainContainer = styled(Container)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;
const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const DrawerModel = styled.div`
    background-color: ${palette.white};
    z-index: 100;
    box-shadow: ${palette.customer_drawer_shadow} 0px 0px 48px 0px;
    min-width: 360px;
    border-radius: 0.75rem;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    ${media.xs`
    width: 100%;
    height: 98%;
    display: block;
	`}
    ${media.sm`
    width: 100%;
    height: 98%;
    display: block;
	`}
    ${media.md`
    width: 100%;
    height: 98%;
    display: block;
	`}
	${media.lg`
    width: 400px;
	`}
`;
const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    margin-bottom: 1rem;
`;
const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
`;
const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
    margin-top: 0.5rem;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 1rem;
    height: calc(100% - 84px);
    // overflow: scroll;
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0.5rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const SelectServiceContent = styled.div`
    border: 1px dashed ${palette.select_box};
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
    background: ${palette.white};
`;
const ServiceHeader = styled.div`
    color: ${palette.grey.grey_20};
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const ToggleButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;

const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;
const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;
const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: ${palette.grey.grey_20};
`;
const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;
const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;
const SelectServiceBody = styled.div`
    border: 1px dashed ${palette.select_box};
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    margin-top: 0.5rem /* 32px */;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const Icons = styled.i`
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;
const Name = styled.div`
    font-weight: 500;
`;
const Email = styled.div`
    color: ${palette.grey.grey_20};
    font-size: 0.9rem;
`;

const SelectCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(49 47 51 / 10%);
    background: ${palette.white};
    padding: 0.2rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
    margin-top: 0.5rem;
    justify-content: space-between;
`;

export default NewPackageDrawer;
