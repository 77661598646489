/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PackagesService Component
 * Description: This component allows users to manage service packages, providing functionality to add, edit, and delete packages.
 * It includes a form for adding/editing packages, a table to display existing packages, and actions like delete and edit for each package.
 *
 */

import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, Layout, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { api } from 'helpers/auth-axios';
import { CustomButtom } from '../businessDetails/BusinessDetails';
import { CustomRow } from '@components/team/create/createTeamMember/CreateTeamMember';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NotFound } from '../../team/list/serviceProviderList/ServiceProviderList';
import { HeaderCustom } from '../businessServices/EditService';
import MultiSelect from '@components/common/select/MultiSelect';
import Drawer from '@components/common/drawer/Drawer';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import { AddAssets } from '../businessServices/CreateService';
import { Message } from '@components/common/input/Input';
import PackageCard from './packageCard';
import SelectServices from './SelectServices';

const PackagesService = ({ onClose, onSelectPackage }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [packages, setPackages] = useState<any>([]);

    const [newPackage, setNewPackage] = useState(false);
    const [addedAssets, setAddedAssets] = useState<
        {
            services_group: {
                service_id: string;
                name: string;
                price: string;
                duration: string;
            }[];
            quantity: number;
        }[]
    >([]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        label_id: Yup.string().required(t('Please select a category')),
    });
    const [assetQuantity, setAssetQuantity] = useState<any>(0);
    const [assetName, setAssetName] = useState<any>();
    const [editingAssetIndex, setEditingAssetIndex] = useState<any>(null);
    const [editAssetQuantity, setEditAssetQuantity] = useState<any>(0);
    const [editAssetName, setEditAssetName] = useState<any>();
    const [assetData, setAssetData] = useState<any>();
    const [selectedServices, setSelectedServices] = useState<any[]>([]);
    const [throughErrors, setThroughError] = useState<any>(false);
    const [serviceError, setServiceError] = useState<any>(false);
    const [showOnWidget, setShowOnWidget] = useState<boolean>(false);
    const [availableByCall, setAvailableByCall] = useState<boolean>(false);
    const [validUntil, setValidUntil] = useState<string>('');
    const [categories, setCategories] = useState<{ label: string; value: string }[]>([]);
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, getValues, formState, trigger, clearErrors, reset } =
        useForm(formOptions);
    const { errors } = formState;
    const [loading, setLoading] = useState(false);
    const [services, setService] = useState<any>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [editPackage, setEditPackage] = useState<any>('');
    const [packageToDelete, setPackageToDelete] = useState<string | null>(null);
    const [deletePackageModel, setDeletePackageModel] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [serviceProviders, setServiceProviders] = useState<any[]>([]);
    const [selectedServiceProviders, setSelectedServiceProviders] = useState<any[]>([]);

    useEffect(() => {
        getPackagesService();
        getBusinessServices();
        getLabelServices();
    }, []);

    const getLabelServices = async () => {
        setLoading(true);
        await api
            .get('/labeled-services')
            .then((res: any) => {
                setLoading(false);
                if (res.data) {
                    let arr = res.data?.map((re: any) => ({ label: re?.name, value: re?._id }));
                    setCategories(arr);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllServiceProvider();
    }, []);

    const resetForm = () => {
        reset({
            name: '',
            label_id: '',
            price: '',
            service_providers: [],
        });
        setNewPackage(false);
        setEditPackage(null);
        setAddedAssets([]);
        setAssetQuantity(0);
        setSelectedServices([]);
        setThroughError(false);
        setShowOnWidget(false);
        setAvailableByCall(false);
        setValidUntil('');
        setSelectedServiceProviders([]);
        clearErrors(['name', 'services']);
    };

    const getAllServiceProvider = async () => {
        setLoading(true);
        try {
            const { data }: { data: { serviceProvider: any[] } } = await api.get(
                '/serviceProvider',
                {
                    params: {
                        page: 1,
                        pageSize: 20,
                    },
                }
            );

            if (!data.serviceProvider) {
                throw new Error('Response does not contain serviceProvider');
            }

            setServiceProviders(data.serviceProvider);
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
            openSnackbar(t('Failed to load service providers!'));
            console.error('Error fetching service providers:', e);
        }
    };

    useEffect(() => {
        if (editPackage) {
            setSelectedServiceProviders(
                serviceProviders
                    .filter(sp => editPackage.service_providers.includes(sp._id))
                    .map(sp => ({ label: sp.name, value: sp }))
            );
        }

        setSelectedServices([]);
    }, [editPackage, serviceProviders]);

    async function onChange({ name, value }: { name: string; value: any }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = async (data: any) => {
        if (!getValues('price') || addedAssets.length === 0 || !validUntil) {
            openSnackbar(t('Please fill all required fields!'));
            setServiceError(true);
            return;
        }

        const serviceProvidersIds = selectedServiceProviders.map(s => s.value._id);

        const updatedServicesDetails = addedAssets.map(group => ({
            services_group: group.services_group.map(service => ({
                service_id: service.service_id,
                name: service.name,
                price: service.price,
                duration: service.duration,
            })),
            total_visits: group.quantity,
        }));

        const serviceIds = updatedServicesDetails
            .map(group => group.services_group.map(service => service.service_id))
            .reduce((acc, val) => acc.concat(val), []);

        setLoading(true);
        try {
            const response = await api.post('package-service/create', {
                ...data,
                price: getValues('price'),
                show_on_widget: showOnWidget,
                available_by_call: availableByCall,
                valid_until: validUntil,
                services: Array.from(new Set(serviceIds)),
                services_details: updatedServicesDetails,
                service_providers: serviceProvidersIds,
            });

            if (response.status === 201) {
                setPackages((prev: any) => [...prev, response.data]);
            }

            setLoading(false);
            setNewPackage(false);
            setAddedAssets([]);
            setAssetQuantity(0);
            setSelectedServices([]);
            setServiceError(false);
            openSnackbar(t('Package added successfully!'));
            resetForm();
        } catch (e: any) {
            setLoading(false);
            openSnackbar(e.response?.data?.message || t('Something went wrong!'));
        }
    };

    const onUpdateSubmit = async (data: any) => {
        if (!editPackage) return;
        const serviceProvidersIds = selectedServiceProviders.map(s => s.value._id);
        setLoading(true);

        try {
            const updatedData = {
                ...data,
                price: getValues('price'),
                show_on_widget: showOnWidget,
                available_by_call: availableByCall,
                valid_until: validUntil,
                services: Array.from(
                    new Set(
                        (addedAssets.length > 0 ? addedAssets : editPackage.services_details)
                            .map((group: any) =>
                                group.services_group.map((service: any) => service.service_id)
                            )
                            .reduce((acc: any, val: any) => acc.concat(val), [])
                    )
                ),
                services_details: (addedAssets.length > 0
                    ? addedAssets
                    : editPackage.services_details
                ).map((group: any) => ({
                    services_group: group.services_group.map((service: any) => ({
                        service_id: service.service_id,
                        name: service.name,
                        price: service.price,
                        duration: service.duration,
                    })),
                    total_visits: group.quantity,
                })),
                service_providers: serviceProvidersIds,
            };

            const response = await api.put(`package-service/${editPackage._id}`, updatedData);

            if (response.status === 200) {
                setPackages((prev: any) =>
                    prev.map((pkg: any) => (pkg._id === editPackage._id ? response.data : pkg))
                );
            }

            await api.put(`package-service/${editPackage._id}`, updatedData);
            setLoading(false);
            setNewPackage(false);
            setEditPackage(null);
            setAddedAssets([]);
            openSnackbar(t('Package updated successfully!'));
            getPackagesService();
            resetForm();
        } catch (error: any) {
            setLoading(false);
            openSnackbar(error?.response?.data?.message || t('Something went wrong!'));
        }
    };

    const getPackagesService = async () => {
        setLoading(true);
        await api
            .get(`package-service`)
            .then(res => {
                setEditPackage('');
                setPackages(res.data);
                console.log(res.data);
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!editPackage) return;
        if (categories.length > 0) {
            const selectedCategory = categories.find(c => c.value === editPackage.label_id);
            if (selectedCategory && getValues('label_id') !== selectedCategory.value) {
                setValue('label_id', selectedCategory.value);
            }
        }

        if (services.length > 0) {
            const filterServices = services.filter((e: any) =>
                editPackage?.services?.some((re: any) => re?._id === e?.value?._id)
            );

            if (JSON.stringify(getValues('services')) !== JSON.stringify(filterServices)) {
                setValue('services', filterServices);
            }
        }

        const formattedAssets =
            editPackage?.services_details?.map((group: any) => ({
                services_group: group.services.map((service: any) => ({
                    service_id: service.service_id,
                    name: service.name,
                    price: service.price,
                    duration: service.duration,
                })),
                quantity: group.total_visits,
            })) || [];

        if (JSON.stringify(addedAssets) !== JSON.stringify(formattedAssets)) {
            setAddedAssets(formattedAssets);
        }
    }, [editPackage, services]);

    const formatTime = (hours: any) => {
        const duration = moment.duration(hours, 'minutes');
        const formattedTime = [];

        if (duration.hours() > 0) {
            formattedTime.push(`${duration.hours()} ${duration.hours() === 1 ? 'hour' : 'hours'}`);
        }

        if (duration.minutes() > 0) {
            formattedTime.push(
                `${duration.minutes()} ${duration.minutes() === 1 ? 'minute' : 'minutes'}`
            );
        }

        return formattedTime.join(' ');
    };

    const getBusinessServices = async () => {
        try {
            setLoading(true);
            await api
                .get('/services')
                .then((res: any) => {
                    setLoading(false);
                    if (res.data?.length) {
                        let data = res.data?.map((e: any) => ({
                            label: `${e?.name})`,
                            value: e,
                        }));
                        setService(data);
                    }
                })
                .catch(e => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const deletePackage = async () => {
        setLoading(true);

        try {
            const response = await api.delete(`/package-service/${packageToDelete}`);

            if (response.status === 200) {
                console.log('✅ Успешное удаление');
                await getPackagesService();
                openSnackbar(t('Package deleted successfully!'));
            }
        } catch (error) {
            openSnackbar(t('Failed to delete package!'));
        } finally {
            setLoading(false);
            setDeletePackageModel(false);
            setPackageToDelete(null);
        }
    };

    const confirmDeletePackage = (id: string) => {
        setPackageToDelete(id);
        setDeletePackageModel(true);
    };

    const groupedPackages = packages.reduce((acc: any, pkg: any) => {
        const category = categories.find(c => c.value === pkg.label_id)?.label || 'Без категории';
        if (!acc[category]) acc[category] = [];
        acc[category].push(pkg);
        return acc;
    }, {});

    const handleAddGroup = () => {
        if (selectedServices.length === 0 || assetQuantity <= 0) {
            setThroughError(true);
            return;
        }
        console.log(selectedServices);
        const newGroup = {
            services_group: selectedServices.map(service => ({
                service_id: service.value._id,
                name: service.label,
                price: service.value.price,
                duration: service.value.duration,
            })),
            quantity: assetQuantity,
        };

        setAddedAssets(prev => {
            const updated = [...prev, newGroup];
            return updated;
        });

        setSelectedServices([]);
        setAssetQuantity(0);
        setThroughError(false);
    };
    const handleEditGroup = (index: number) => {
        if (index < 0 || index >= addedAssets.length) return;

        const updatedGroups = [...addedAssets];

        const existingServices = updatedGroups[index]?.services_group || [];

        const updatedServices = selectedServices
            .map(service => {
                if (!service.value || !service.value._id) {
                    console.error('Ошибка: Некорректный объект услуги', service);
                    return null;
                }

                const existingService = existingServices.find(
                    s => s.service_id === service.value._id
                );

                return existingService
                    ? existingService
                    : {
                          service_id: service.value._id,
                          name: service.label,
                          price: service.value.price.toString(),
                          duration: service.value.duration.toString(),
                      };
            })
            .filter(
                (s): s is { service_id: string; name: string; price: string; duration: string } =>
                    s !== null
            );

        const uniqueServices = Array.from(new Set(updatedServices.map(s => s.service_id)))
            .map(id => updatedServices.find(s => s.service_id === id))
            .filter(
                (s): s is { service_id: string; name: string; price: string; duration: string } =>
                    s !== undefined
            );

        updatedGroups[index] = {
            services_group: uniqueServices,
            quantity: assetQuantity || updatedGroups[index].quantity,
        };

        setAddedAssets(updatedGroups);
        setSelectedServices([]);
        setAssetQuantity(1);
        setEditingAssetIndex(null);
    };

    const handleRemoveGroup = (index: number) => {
        setAddedAssets(prev => prev.filter((_, i) => i !== index));
    };

    const handleSelectGroupForEdit = (index: number) => {
        const selectedGroup = addedAssets[index];

        setEditingAssetIndex(index);

        setSelectedServices(
            selectedGroup.services_group.map(service => ({
                label: service.name,
                value: {
                    _id: service.service_id,
                    name: service.name,
                    price: service.price,
                    duration: service.duration,
                },
            }))
        );

        setAssetQuantity(selectedGroup.quantity);
    };

    return (
        <>
            <Wrapper>
                <CustomRow>
                    {loading && <LoaderOverlay />}
                    {newPackage === false ? (
                        <Col lg={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '1rem 0',
                                    justifyContent: 'space-between',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    {/* {onClose ? (
                                        ''
                                    ) : (
                                        <Link to="/settings/promotions">
                                            <Icon className="fal fa-chevron-left"></Icon>
                                        </Link>
                                    )} */}
                                    <Header>{t('Manage Packages')}</Header>
                                </div>
                                <div style={{ gap: '0.5rem', display: 'flex' }}>
                                    <Button
                                        label={t('Add new package')}
                                        bgtype="secondary"
                                        ifClicked={() => setNewPackage(true)}></Button>
                                </div>
                            </div>
                            {packages?.length ? (
                                <div>
                                    {Object.entries(groupedPackages).map(
                                        ([category, packages]: any) => (
                                            <div key={category} style={{ marginBottom: '2rem' }}>
                                                <h3
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        marginBottom: '1rem',
                                                    }}>
                                                    {category}
                                                </h3>
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        gridTemplateColumns:
                                                            'repeat(auto-fill, minmax(250px, 1fr))',
                                                        gap: '1rem',
                                                    }}>
                                                    {packages.map((pkg: any) => (
                                                        <PackageCard
                                                            key={pkg._id}
                                                            pkg={pkg}
                                                            onClick={
                                                                onSelectPackage
                                                                    ? () => onSelectPackage(pkg)
                                                                    : undefined
                                                            }
                                                            isSelectable={!!onSelectPackage}
                                                            onEdit={(id: string) => {
                                                                const packageToEdit = packages.find(
                                                                    (p: any) => p._id === id
                                                                );
                                                                if (packageToEdit) {
                                                                    setEditPackage(packageToEdit);
                                                                    setSelectedServices(
                                                                        packageToEdit.services.map(
                                                                            (service: any) => ({
                                                                                value: service._id,
                                                                                label: service.name,
                                                                                price: service.price,
                                                                                duration:
                                                                                    service.duration,
                                                                            })
                                                                        )
                                                                    );
                                                                    setValidUntil(
                                                                        packageToEdit.valid_until ||
                                                                            ''
                                                                    );
                                                                    setShowOnWidget(
                                                                        packageToEdit.show_on_widget
                                                                    );
                                                                    setAvailableByCall(
                                                                        packageToEdit.available_by_call
                                                                    );
                                                                    setValue(
                                                                        'name',
                                                                        packageToEdit.name
                                                                    );
                                                                    setValue(
                                                                        'price',
                                                                        packageToEdit.price
                                                                    );
                                                                    setNewPackage(true);
                                                                }
                                                            }}
                                                            onDelete={() =>
                                                                confirmDeletePackage(pkg._id)
                                                            }
                                                            currency={
                                                                _userData?.user?.business_id
                                                                    ?.currency || '€'
                                                            }
                                                            isEditable={true}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <CustomDiv>
                                    {packages?.length == 0 && loading == false && (
                                        <NotFound>{t('No Record Found!')}</NotFound>
                                    )}
                                </CustomDiv>
                            )}
                        </Col>
                    ) : (
                        <Col lg={12}>
                            <HeaderCustom lg={12} style={{ marginTop: '1rem' }}>
                                <HeadingAddCard>
                                    {t(editPackage ? 'Update Package' : 'Create New Package')}
                                </HeadingAddCard>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => {
                                        if (!loading) {
                                            setNewPackage(false);
                                            setEditPackage('');
                                            setAssetQuantity(0);
                                            setAssetName('');
                                            setSelectedServices([]);
                                            setThroughError(false);
                                            setAddedAssets([]);
                                            clearErrors(['name', 'services']);
                                            resetForm();
                                        }
                                    }}></Cross>
                            </HeaderCustom>
                            <Card>
                                <form
                                    onSubmit={handleSubmit(
                                        editPackage ? onUpdateSubmit : onSubmit
                                    )}>
                                    <Wrapper>
                                        <Row>
                                            <CustomCol lg={12}>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Name')}
                                                        name="name"
                                                        value={getValues('name')}
                                                        onChange={onChange}
                                                        error={errors?.name as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Select
                                                        label={t('Choose Category')}
                                                        options={categories}
                                                        disabled={true}
                                                        value={
                                                            getValues('label_id')
                                                                ? categories.find(
                                                                      (e: any) =>
                                                                          e?.value ==
                                                                          getValues('label_id')
                                                                  )?.label
                                                                : ''
                                                        }
                                                        onChange={(val: string) => {
                                                            onChange({
                                                                name: 'label_id',
                                                                value: val,
                                                            });
                                                        }}
                                                        error={errors.label_id as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup style={{ gap: '0.3rem' }}>
                                                    <MultiSelect
                                                        isMulti
                                                        label={t('Choose Service Providers')}
                                                        name="service_providers"
                                                        value={selectedServiceProviders}
                                                        onChange={(e: any) => {
                                                            setSelectedServiceProviders(e);
                                                        }}
                                                        options={serviceProviders.map(
                                                            (sp: any) => ({
                                                                label: sp.name,
                                                                value: sp,
                                                            })
                                                        )}
                                                    />
                                                </InputGroup>

                                                <Wrapper>
                                                    <Row>
                                                        <Col lg={5}>
                                                            {/* <InputGroup style={{ gap: '0.3rem' }}>
                                                                <MultiSelect
                                                                    isMulti
                                                                    label={t('Choose Service')}
                                                                    name="service"
                                                                    value={selectedServices}
                                                                    onChange={(e: any) => {
                                                                        setThroughError(false);
                                                                        setSelectedServices(e);
                                                                        setAssetData(
                                                                            e.map((s: any) => ({
                                                                                service_id:
                                                                                    s.value._id,
                                                                                name: s.label,
                                                                                price: s.value
                                                                                    .price,
                                                                                duration:
                                                                                    s.value
                                                                                        .duration,
                                                                            }))
                                                                        );
                                                                    }}
                                                                    options={services.filter(
                                                                        (e: any) =>
                                                                            !addedAssets.some(
                                                                                (re: any) =>
                                                                                    re.name ===
                                                                                    e.label
                                                                            )
                                                                    )}
                                                                />
                                                            </InputGroup> */}
                                                            <div
                                                                onClick={() =>
                                                                    setIsDrawerOpen(true)
                                                                }
                                                                style={{
                                                                    border: '1px solid #ddd',
                                                                    padding: '10px',
                                                                    borderRadius: '8px',
                                                                    cursor: 'pointer',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    background: '#fff',
                                                                }}>
                                                                {/* {selectedServices.length > 0 ? (
                                                                    <div>
                                                                        {selectedServices.map(
                                                                            service => (
                                                                                <div
                                                                                    key={
                                                                                        service
                                                                                            .value
                                                                                            ._id
                                                                                    }
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            '500',
                                                                                        marginBottom:
                                                                                            '5px',
                                                                                    }}>
                                                                                    {`${service.value.name} (price: ${service.value.price}, duration: ${service.value.duration} min)`}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ color: '#aaa' }}>
                                                                    {t('Select a service')}
                                                                </span>
                                                                
                                                                )} */}
                                                                {selectedServices.length > 0 ? (
                                                                    <div>
                                                                        {selectedServices
                                                                            .filter(
                                                                                service =>
                                                                                    service?.value
                                                                            ) // Фильтруем только корректные объекты
                                                                            .map(service => (
                                                                                <div
                                                                                    key={
                                                                                        service
                                                                                            ?.value
                                                                                            ?._id ||
                                                                                        Math.random()
                                                                                    } // Генерируем временный ключ, если _id нет
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            '500',
                                                                                        marginBottom:
                                                                                            '5px',
                                                                                    }}>
                                                                                    {`${
                                                                                        service
                                                                                            ?.value
                                                                                            ?.name ||
                                                                                        'Unknown'
                                                                                    }
                    `}
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ color: '#aaa' }}>
                                                                        {t('Select a service')}
                                                                    </span>
                                                                )}

                                                                <i
                                                                    className="fal fa-chevron-right"
                                                                    style={{ color: '#aaa' }}></i>
                                                            </div>
                                                        </Col>
                                                        <Col lg={5}>
                                                            <InputGroup>
                                                                <Input
                                                                    name={'quantity'}
                                                                    label={`${t(
                                                                        'No. Of Appointments'
                                                                    )}`}
                                                                    allowOnlyNumbersAndDecimal={
                                                                        true
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        setThroughError(false);
                                                                        const trimmedValue =
                                                                            parseFloat(e?.value) ||
                                                                            0;
                                                                        setAssetQuantity(
                                                                            trimmedValue.toString()
                                                                        );
                                                                    }}
                                                                    value={assetQuantity}
                                                                    error={
                                                                        throughErrors &&
                                                                        assetQuantity <= 0
                                                                            ? {
                                                                                  message: t(
                                                                                      'No. Of Appointments is required'
                                                                                  ),
                                                                              }
                                                                            : undefined
                                                                    }
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <AddAssets
                                                                onClick={() => {
                                                                    if (
                                                                        editingAssetIndex !== null
                                                                    ) {
                                                                        handleEditGroup(
                                                                            editingAssetIndex
                                                                        );
                                                                    } else {
                                                                        handleAddGroup();
                                                                    }
                                                                }}>
                                                                {t(
                                                                    editingAssetIndex !== null
                                                                        ? 'Update'
                                                                        : 'Add'
                                                                )}
                                                            </AddAssets>
                                                        </Col>
                                                    </Row>
                                                    {addedAssets.length > 0 && (
                                                        <div>
                                                            {addedAssets.map((group, index) => (
                                                                <GroupWrapper key={index}>
                                                                    <GroupHeader>
                                                                        <span>
                                                                            {t('Appointments')}:{' '}
                                                                            {group.quantity}
                                                                        </span>
                                                                        <GroupActions>
                                                                            <i
                                                                                className="fal fa-edit"
                                                                                onClick={() =>
                                                                                    handleSelectGroupForEdit(
                                                                                        index
                                                                                    )
                                                                                }></i>
                                                                            <i
                                                                                className="fal fa-trash-alt"
                                                                                onClick={() =>
                                                                                    handleRemoveGroup(
                                                                                        index
                                                                                    )
                                                                                }></i>
                                                                        </GroupActions>
                                                                    </GroupHeader>
                                                                    <ServiceList>
                                                                        {group.services_group.map(
                                                                            service => (
                                                                                <li
                                                                                    key={
                                                                                        service.service_id
                                                                                    }>
                                                                                    <span>
                                                                                        {
                                                                                            service.name
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ServiceList>
                                                                </GroupWrapper>
                                                            ))}
                                                        </div>
                                                    )}
                                                </Wrapper>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Price')}
                                                        name="price"
                                                        allowOnlyNumbersAndDecimal={true}
                                                        value={getValues('price')}
                                                        onChange={onChange}
                                                        error={errors?.price as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={showOnWidget}
                                                            onChange={e =>
                                                                setShowOnWidget(e.target.checked)
                                                            }
                                                        />
                                                        <span style={{ marginLeft: '0.5rem' }}>
                                                            {t('Available to booking')}
                                                        </span>
                                                    </label>
                                                </InputGroup>
                                                <InputGroup>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={availableByCall}
                                                            onChange={e =>
                                                                setAvailableByCall(e.target.checked)
                                                            }
                                                        />
                                                        <span style={{ marginLeft: '0.5rem' }}>
                                                            {t('Available by phone')}
                                                        </span>
                                                    </label>
                                                </InputGroup>
                                                <InputGroup>
                                                    <label style={{ fontWeight: 'bold' }}>
                                                        {t('Valid Until')}
                                                    </label>
                                                    <input
                                                        type="date"
                                                        value={validUntil}
                                                        onChange={e =>
                                                            setValidUntil(e.target.value)
                                                        }
                                                    />
                                                </InputGroup>
                                                {addedAssets?.length ? (
                                                    <>
                                                        {/* <FooterDiv>
                                                            <Label>{t('Duration')}</Label>
                                                            <LabelValue>
                                                            {addedAssets?.length
                                                            ? formatTime(
                                                                addedAssets?.reduce(
                                                                    (a: any, b: any) =>
                                                                        a + parseInt(b?.service?.duration) * b?.quantity,
                                                                    0
                                                                )
                                                            )
                                                            : t('0 minute')}
                                                            </LabelValue>
                                                        </FooterDiv> */}
                                                        <FooterDiv>
                                                            <Label>{t('Total Price')}</Label>
                                                            <LabelValue>
                                                                {
                                                                    _userData?.user?.business_id
                                                                        ?.currency
                                                                }
                                                                {getValues('price') || 0}
                                                            </LabelValue>
                                                        </FooterDiv>
                                                        <FooterDiv></FooterDiv>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {serviceError && addedAssets?.length == 0 && (
                                                    <Message>
                                                        {t('Please add Services in package')}
                                                    </Message>
                                                )}
                                                <CustomButtom>
                                                    <Button
                                                        type="submit"
                                                        bgtype="secondary"
                                                        label={t(editPackage ? 'Update' : 'Add')}
                                                    />
                                                </CustomButtom>
                                            </CustomCol>
                                        </Row>
                                    </Wrapper>
                                </form>
                            </Card>
                        </Col>
                    )}
                </CustomRow>
            </Wrapper>
            {deletePackageModel && (
                <DeleteModel
                    onClose={() => setDeletePackageModel(false)}
                    onClick={() => deletePackage()}
                    name={t('Package')}
                    content={t('Are you sure you want to delete this package?')}
                    loading={loading}
                />
            )}
            {isDrawerOpen && (
                <SelectServices
                    selectedServices={selectedServices.map(service => service.value)} // 🔹 Передаем только value
                    onClose={() => setIsDrawerOpen(false)}
                    onSelect={services => {
                        setSelectedServices(
                            services.map(service => ({
                                label: `${service.name}`,
                                value: service,
                            }))
                        );
                        setIsDrawerOpen(false);
                    }}
                    modelStatus="editservice"
                />
            )}
        </>
    );
};

const FooterDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

const Label = styled.span`
    font-weight: bold;
`;

const LabelValue = styled.span`
    font-weight: normal;
`;

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CustomCol = styled(Col)`
    /* border-right: 0.1rem solid ${palette.grey.lightest}; */
    ${media.xs`
    border-right: none;
  `}
    ${media.sm`
    border-right: none;
  `}
    ${media.md`
    border-right: none;
  `}
    ${media.lg`
    /* border-right: 0.1rem solid ${palette.grey.lightest}; */
  `}
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

const CustomDiv = styled.div`
    margin-top: 2rem;
`;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
    /* margin-right: 1.5rem !important; */
`;

export const HeadingAddCard = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

const GroupWrapper = styled.div`
    background: ${palette.grey.lightest}; // Светло-серый фон
    border-radius: 12px; // Скругление углов
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05); // Легкая тень
`;

const GroupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
`;

const GroupActions = styled.div`
    display: flex;
    gap: 0.75rem;

    i {
        font-size: 1.2rem;
        cursor: pointer;
        color: ${palette.primary}; // Основной цвет кнопок

        &:hover {
            color: ${palette.secondary}; // Вторичный цвет при наведении
        }
    }
`;

const ServiceList = styled.ul`
    padding-left: 1rem;
    font-size: 0.9rem;

    li {
        margin-bottom: 0.3rem;
        list-style: none;
        display: flex;
        justify-content: space-between;

        span {
            font-weight: 500;
        }
    }
`;

const DrawerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
`;

const DrawerContent = styled.div`
    width: 400px;
    height: 100vh;
    background: #fff;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5rem;
    transition: transform 0.3s ease-in-out;
`;

const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
`;

const DrawerTitle = styled.h3`
    font-size: 1.4rem;
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #777;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: #000;
    }
`;

const DrawerBody = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 1rem;
`;

PackagesService.Layout = Layout;
export default PackagesService;
