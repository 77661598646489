import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useTranslation } from 'react-i18next';
import { api } from 'helpers/auth-axios';
import { Spinner } from '@components/common';
import { palette } from 'styled/common';
import PackageCard from '../../settings/PromotionsAndGiftCard/packageCard';

interface SelectPackageProps {
    onClose: () => void;
    onSelect: (pkg: Package) => void;
    selectedPackage?: Package;
    id: string;
}

interface Package {
    _id: string;
    name: string;
    price: string;
    valid_until: string | null;
    show_on_widget: boolean;
    available_by_call: boolean;
    total_sold: number;
    services: Service[];
    services_details: ServiceGroup[];
    label_id: string;
}

interface ServiceGroup {
    total_visits: number;
    services: Service[];
}

interface Service {
    service_id: string;
    name: string;
    price: string;
    duration: string;
}

const SelectPackage: React.FC<SelectPackageProps> = ({
    onClose,
    onSelect,
    selectedPackage,
    id,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [packages, setPackages] = useState<Package[]>([]);
    const [categories, setCategories] = useState<{ label: string; value: string }[]>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        getLabelServices();
        getPackages();
    }, []);

    const getLabelServices = async () => {
        setLoading(true);
        await api
            .get('/labeled-services')
            .then((res: any) => {
                setLoading(false);
                if (res.data) {
                    let arr = res.data?.map((re: any) => ({ label: re?.name, value: re?._id }));
                    setCategories(arr);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const getPackages = async () => {
        setLoading(true);
        try {
            const res = await api.get(`/package-service/service-provider/${id}`);
            setPackages(res.data);
        } catch (e) {
            console.error('Ошибка при получении пакетов', e);
        } finally {
            setLoading(false);
        }
    };

    const groupedPackages = packages.reduce((acc: any, pkg: Package) => {
        const category = categories.find(c => c.value === pkg.label_id)?.label || 'Без категории';
        if (!acc[category]) acc[category] = [];
        acc[category].push(pkg);
        return acc;
    }, {});

    return (
        <Container>
            <Row>
                <ServicesContent>
                    <HeaderContainer>
                        <CloseButton onClick={onClose}>
                            <Icon className="fal fa-chevron-left"></Icon>
                        </CloseButton>
                        <Header>{t('Select Package')}</Header>
                    </HeaderContainer>
                    {loading ? (
                        <LoadingContainer>
                            PackageGrid
                            <Spinner color={'black'} />
                        </LoadingContainer>
                    ) : (
                        <ServicesBody>
                            {packages?.length ? (
                                <Wrapper>
                                    {Object.entries(groupedPackages).map(
                                        ([category, packages]: any) => (
                                            <div key={category} style={{ marginBottom: '2rem' }}>
                                                <GroupTitle>{category}</GroupTitle>
                                                <>
                                                    {packages.map((pkg: Package) => (
                                                        <PackageCard
                                                            key={pkg._id}
                                                            pkg={pkg}
                                                            isSelectable={true}
                                                            onClick={() => onSelect(pkg)}
                                                            currency={
                                                                _userData.user.business_id.currency
                                                            }
                                                            onEdit={() => {}}
                                                            onDelete={() => {}}
                                                        />
                                                    ))}
                                                </>
                                            </div>
                                        )
                                    )}
                                </Wrapper>
                            ) : (
                                <div>{t('No Package available for this serviceProvider')}</div>
                            )}
                        </ServicesBody>
                    )}
                </ServicesContent>
            </Row>
        </Container>
    );
};

const PackagesContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;

const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    line-height: 2.5rem;
`;

const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

const PackagesBody = styled.div`
    padding: 0 1.75rem;
    overflow-y: auto;
    height: 44rem;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44rem;
`;

const GroupTitle = styled.h3`
    text-align: center;
`;

const PackageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    position: relative;
`;

const ServicesContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GroupWrapper = styled.div`
    display: grid;
    // grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 1rem /* 16px */;
`;

const ServicesBody = styled.div`
    padding: 0 1.75rem;
    overflow-y: auto;
    height: 44rem;
`;
const Wrapper = styled.div`
    display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem /* 16px */;
`;

const ServicesList = styled.div`
    border-radius: 0.5rem !important;
    padding: 26px 20px !important;
    position: relative;
    cursor: pointer !important;
`;

const Label = styled.div`
    background-color: ${palette.green};
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${palette.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 0.8rem;
    width: 140px;
    white-space: pre-wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    font-size: 0.7rem;
`;

const CustomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default SelectPackage;
