/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SelectServices Component
 * Description: This component is used to select services. It displays a list of services with options to add or remove them. The component supports search, pagination, and lazy loading for improved performance. It also provides buttons to cancel or save the selected services.
 *
 * Props:
 * - selectedServices: IService[] - An array of initially selected services.
 * - modelStatus: string - The status of the model ('editservice' in this case).
 * - onClose: () => void - Callback function to close the modal.
 * - onSelect: (services: IService[]) => void - Callback function to handle the selection of services.
 * - submitLoading?: boolean - Loading state for submit action.
 *
 */

import { useEffect, useState } from 'react';
import s from './SelectServices.module.scss';
import { IService } from 'interfaces/service.interface';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import { LoaderOverlay } from '@components/common';
import { palette } from 'styled/common';

const SelectServices = ({
    selectedServices,
    modelStatus,
    onClose,
    onSelect,
    submitLoading,
}: {
    selectedServices: IService[];
    modelStatus: string;
    onClose(): void;
    onSelect(services: IService[]): void;
    submitLoading?: boolean;
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<IService[]>([]);
    const [services, setServices] = useState<IService[]>([]);
    const [loading, setLoading] = useState(false);
    const _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [categories, setCategories] = useState<any[]>([]);

    console.log(services);

    useEffect(() => {
        getServices();
    }, []);

    useEffect(() => {
        getLabelServices();
    }, []);

    useEffect(() => {
        const selectedServicesClone: IService[] = [];
        if (services.length) {
            if (selectedServices.length) {
                selectedServices.forEach(service => {
                    selectedServicesClone.push(service);
                });
            }

            setSelected(selectedServicesClone);
        }
    }, [services, selectedServices]);

    // const toggleSelect = (service: IService) => {
    //     if (selected.find(item => item._id === service._id)) {
    //         const newSelected = selected.filter(item => item._id !== service._id);
    //         setSelected(newSelected);
    //     } else {
    //         setSelected([...selected, service]);
    //         console.log(selected);
    //         console.log(service);
    //     }
    // };

    const getLabelServices = async () => {
        setLoading(true);
        await api
            .get('/labeled-services')
            .then((res: any) => {
                setLoading(false);
                if (res.data) {
                    setCategories(res.data);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    // const toggleSelect = (service: IService) => {
    //     const labelId = service.label_id;

    //     const newService = {
    //         ...service,
    //         label_id: labelId,
    //     };

    //     if (selected.find(item => item._id === newService._id)) {
    //         const newSelected = selected.filter(item => item._id !== newService._id);
    //         setSelected(newSelected);
    //     } else {
    //         setSelected([...selected, newService]);
    //     }
    // };

    const toggleSelect = (service: IService) => {
        if (selected.some(item => item._id === service._id)) {
            setSelected(selected.filter(item => item._id !== service._id));
        } else {
            setSelected([...selected, service]);
        }
    };

    const getServices = async () => {
        setLoading(true);
        try {
            const res = await api.get<IService[]>('/services');
            if (res.data && res.data.length) {
                setServices(res.data);
            }
        } catch (e) {
            console.error('Failed to fetch services:', e);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    // const groupedServices = services
    //     .sort((a, b) => a.label_id.name.localeCompare(b.label_id.name))
    //     .reduce<Record<string, IService[]>>((acc, service) => {
    //         const groupName = service.label_id.name;
    //         if (!acc[groupName]) {
    //             acc[groupName] = [];
    //         }
    //         acc[groupName].push(service);
    //         return acc;
    //     }, {});

    const groupedServices = services
        .sort((a, b) => a.label_id.name.localeCompare(b.label_id.name))
        .reduce<Record<string, IService[]>>((acc, service) => {
            const groupName = service.label_id._id; // Группируем по _id метки, а не по её имени
            if (!acc[groupName]) {
                acc[groupName] = [];
            }
            acc[groupName].push(service);
            return acc;
        }, {});

    return (
        <>
            <Container>
                {(loading || submitLoading) && <LoaderOverlay />}
                <Model tabIndex={-1} onClick={closeModal}>
                    <ModelBody lg={12}>
                        <ModelTitle>
                            <ModelHeading>{t('Select Services')}</ModelHeading>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-times" />
                            </CloseButton>
                        </ModelTitle>
                        <ModelContent>
                            <ServicesList>
                                {Object.entries(groupedServices).map(([groupId, services]) => (
                                    <GroupedList key={groupId}>
                                        <GroupTitle>{services[0].label_id.name}</GroupTitle>
                                        <ServicesGrid>
                                            {services.map((service, i) => (
                                                <Services
                                                    sm={12}
                                                    md={6}
                                                    lg={4}
                                                    key={service._id || i}>
                                                    <div
                                                        className={`${s.services_service} ${
                                                            selected.find(
                                                                item => item._id === service._id
                                                            )
                                                                ? s.services_selectedService
                                                                : ''
                                                        } service-color-${service.color}--bd`}>
                                                        <div
                                                            onClick={() => toggleSelect(service)}
                                                            className={`${
                                                                modelStatus === 'editservice'
                                                                    ? s.service_seleted
                                                                    : s.service
                                                            } service-color-${
                                                                service.color
                                                            }--bg cursor-pointer`}>
                                                            {/* <Label>
                                                                &nbsp;
                                                                {
                                                                    _userData.user.business_id
                                                                        .currency
                                                                }
                                                                {service.price}
                                                            </Label> */}
                                                            <CustomDiv>
                                                                <Name>{service.name}</Name>
                                                                {service?.is_video && (
                                                                    <i className="fal fa-video" />
                                                                )}
                                                            </CustomDiv>
                                                            {/* <ServiceDuration>
                                                                {`${
                                                                    service.duration >= 60
                                                                        ? Math.floor(
                                                                              service.duration / 60
                                                                          ) + 'h'
                                                                        : ''
                                                                } ${service.duration % 60}${t(
                                                                    'min'
                                                                )}`}
                                                            </ServiceDuration> */}
                                                        </div>
                                                    </div>
                                                </Services>
                                            ))}
                                        </ServicesGrid>
                                    </GroupedList>
                                ))}
                            </ServicesList>
                        </ModelContent>
                        <ModelButtons>
                            <div />
                            <CustomButtons>
                                <Button
                                    ifClicked={() => {
                                        onClose();
                                    }}
                                    bgtype={'discard'}
                                    type={'button'}
                                    label={t(`Cancel`)}
                                />
                                &nbsp;
                                <Button
                                    ifClicked={() => {
                                        onSelect(selected);
                                    }}
                                    bgtype={'secondary'}
                                    type={'button'}
                                    label={`${t('Save')} (${selected.length})`}
                                />
                            </CustomButtons>
                        </ModelButtons>
                    </ModelBody>
                </Model>
            </Container>
        </>
    );
};

const Model = styled(Row)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 1.8rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled(Col)`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.8rem -1.4rem rgb(0 0 0 / 10%);
    padding: 1.7rem;
    padding-top: 1rem;
    width: 60rem;
    max-width: 63vw;
    ${media.xs`
  width: 100%;
  max-width: 100%;
`}
    ${media.sm`
  width: 100%;
`}
  ${media.md`
  width: 100%;
`}
`;

const CloseButton = styled.div`
    padding: 0;
    line-height: inherit;
    color: inherit;
    cursor: pointer;
    border: none;
`;
const ModelTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Icon = styled.i`
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
`;

const ModelHeading = styled.h1`
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    ${media.xs`
  font-size: 1.6rem
`}
`;

const ModelContent = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
`;

const ServicesList = styled(Col)`
    overflow: auto;
    padding-top: 2.5rem;
`;

const Services = styled(Col)`
    cursor: pointer;
    margin-bottom: 1rem;
`;

const ModelButtons = styled.div`
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CustomButtons = styled.div`
    display: flex;
    gap: 0.2rem;
    justify-content: space-between;
    ${media.xs`
    display: flex !important;
`}
`;

const Label = styled.div`
    background-color: #00be70;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 0.85rem;
    width: 200px;
    // white-space: nowrap;
    word-break: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    margin-top: 0.2rem;
    font-size: 0.9rem;
`;
const GroupedList = styled(Col)`
    width: 100%;
`;
const ServicesGrid = styled(Row)`
    width: 100%;
`;
const GroupTitle = styled.h3`
    text-align: center;
`;

export default SelectServices;
