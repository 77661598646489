/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateService Component
 * Description: This component handles the creation of a new service, including setting the service name, price, duration, and other details. It allows users to add assets to the service, set the service color, and choose whether it's available for self-booking or is a video service.
 *
 * Function: handleAddAsset
 * Description: Adds a new asset to the list of assets for the service or updates an existing asset if in edit mode. Handles the logic for both adding and updating assets.
 *
 */

import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Layout, LoaderOverlay, useSnackbar } from '@components/common';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Switch } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IService } from 'interfaces/service.interface';
import s from './CreateService.module.scss';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
import { api } from 'helpers/auth-axios';

const CreateService = ({ onCreate }: { onCreate?: any }) => {
    const { t }: any = useTranslation();
    const navigate = useNavigate();
    const [serviceColor, setServiceColor] = useState(Math.floor(Math.random() * 32));
    const [selfBooking, setSelfBooking] = useState(true);
    const [reservationByPhone, setReservationByPhone] = useState(false);
    const [showColorModal, setShowColorModal] = useState(false);
    const [durationHours, setDurationHours] = useState(0);
    const [durationMinutes, setDurationMinutes] = useState(30);
    const [ifClicked, setIfClicked] = useState<boolean>(false);
    const [is_video, setIs_video] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();
    const [assets, setAssets] = useState<any>([]);
    const [addedAssets, setAddedAssets] = useState<any>([]);
    const [assetQuantity, setAssetQuantity] = useState<any>(0);
    const [assetName, setAssetName] = useState<any>();
    const [editingAssetIndex, setEditingAssetIndex] = useState<any>(null);
    const [editAssetQuantity, setEditAssetQuantity] = useState<any>(0);
    const [editAssetName, setEditAssetName] = useState<any>();
    const [assetData, setAssetData] = useState<any>();
    const [categories, setCategories] = useState<any[]>([]);

    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        getBusinessAssets();
        getLabelServices();
    }, []);
    useEffect(() => {
        if (durationHours <= 0) {
            setDurationMinutes(30);
        }
    }, [durationHours]);

    const hoursArr: { label: string; value: number }[] = [];
    for (let i = 0; i < 24; i++) {
        hoursArr.push({
            label: `${i}h`,
            value: i,
        });
    }

    const minutesArr: { label: string; value: number }[] = [];
    for (let i = durationHours > 0 ? 0 : 1; i < 12; i++) {
        minutesArr.push({
            label: `${i * 5}${t('min')}`,
            value: i * 5,
        });
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
        price: Yup.number()
            .moreThan(0, t('Price should not be zero'))
            .typeError(t('A valid number is required'))
            .required(t('This field is required')),
        label_id: Yup.string().required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<IService>({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: any }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        const serviceData = {
            ...data,
            duration: durationHours * 60 + durationMinutes,
            price: parseFloat(data?.price).toFixed(2),
            my_duration: durationHours * 60 + durationMinutes,
            my_price: parseFloat(data?.price).toFixed(2),
            available_for_boooking: selfBooking,
            reservation_by_phone: reservationByPhone,
            color: serviceColor,
            vip_price: parseFloat(data?.price).toFixed(2),
            is_video,
            assets: addedAssets,
        };
        setLoading(true);
        api.post('/services', serviceData)
            .then(() => {
                setLoading(false);
                setIfClicked(true);
                if (onCreate) {
                    onCreate();
                } else {
                    navigate('/services');
                }

                openSnackbar(t('Service added successfully!'));
            })
            .catch(e => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data;
                    if (response) {
                        openSnackbar(response?.message);
                    }
                }
            });
    };

    const changeServiceColor = (color: number) => {
        setServiceColor(color);
        setShowColorModal(false);
    };

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            setShowColorModal(false);
        }
    };

    const handleEditAsset = (index: number) => {
        setEditingAssetIndex(index);
        setEditAssetName(addedAssets[index]?.name);
        setEditAssetQuantity(addedAssets[index]?.quantity);
    };

    const handleUpdateAsset = (index: number) => {
        const updatedAssets = [...addedAssets];
        updatedAssets[index] = {
            ...updatedAssets[index],
            name: editAssetName,
            quantity: editAssetQuantity,
        };
        setAddedAssets(updatedAssets);

        // Reset the editing state
        setEditingAssetIndex(null);
        setEditAssetName('');
        setEditAssetQuantity('');
    };

    const handleCancelEdit = () => {
        setEditingAssetIndex(null);
        setEditAssetName('');
        setEditAssetQuantity('');
    };

    const getBusinessAssets = async () => {
        try {
            setLoading(true);
            await api
                .get('/assets-service')
                .then((res: any) => {
                    setLoading(false);
                    if (res.data?.length) {
                        let data = res.data?.map((e: any) => ({
                            label: e?.name,
                            value: e,
                        }));
                        setAssets(data);
                    }
                })
                .catch(e => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const getLabelServices = async () => {
        setLoading(true);
        await api
            .get('/labeled-services')
            .then((res: any) => {
                setLoading(false);
                if (res.data) {
                    let arr = res.data?.map((re: any) => ({ label: re?.name, value: re?._id }));
                    setCategories(arr);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const handleDeleteAsset = (assets: any) => {
        const updatedAssets = addedAssets?.filter((asset: any) => asset?.name !== assets?.name);

        setAddedAssets(updatedAssets);
    };

    return (
        <MainContent>
            {loading && <LoaderOverlay />}
            <Inner>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        <Row>
                            <Header lg={12}>
                                <Heading>{t('Add Services')}</Heading>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => {
                                        if (onCreate && !loading) {
                                            onCreate();
                                        } else {
                                            !loading && navigate('/services');
                                        }
                                    }}></Cross>
                            </Header>
                            <Col lg={12}>
                                <Card>
                                    <Wrapper>
                                        <Row>
                                            <LeftCol sm={6} md={12} lg={12}>
                                                <CardBody>
                                                    <InputGroup>
                                                        <Select
                                                            label={t('Choose Category')}
                                                            options={categories}
                                                            disabled={true}
                                                            value={
                                                                getValues('label_id')
                                                                    ? categories.find(
                                                                          (e: any) =>
                                                                              e?.value ==
                                                                              getValues('label_id')
                                                                      )?.label
                                                                    : ''
                                                            }
                                                            onChange={(val: string) => {
                                                                onChange({
                                                                    name: 'label_id',
                                                                    value: val,
                                                                });
                                                            }}
                                                            error={errors.label_id as any}
                                                        />
                                                    </InputGroup>
                                                    <InputGroup>
                                                        <Input
                                                            label={t('Service name')}
                                                            name="name"
                                                            onChange={onChange}
                                                            error={errors.name as any}
                                                        />
                                                    </InputGroup>
                                                    <InputGroup>
                                                        <Input
                                                            label={t('Service description')}
                                                            name="description"
                                                            onChange={onChange}
                                                            error={errors.description as any}
                                                        />
                                                    </InputGroup>

                                                    <Wrapper>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <InputGroup>
                                                                    <Select
                                                                        label={t('Hour(s)')}
                                                                        options={hoursArr}
                                                                        disabled={true}
                                                                        value={durationHours + 'h'}
                                                                        onChange={(val: string) => {
                                                                            setDurationHours(
                                                                                parseInt(val)
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <InputGroup>
                                                                    <Select
                                                                        label={t('Minutes')}
                                                                        options={minutesArr}
                                                                        disabled={true}
                                                                        value={
                                                                            durationMinutes +
                                                                            t('min')
                                                                        }
                                                                        onChange={(val: string) => {
                                                                            setDurationMinutes(
                                                                                parseInt(val)
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                    </Wrapper>

                                                    <Wrapper>
                                                        <Row>
                                                            <Col lg={5}>
                                                                <InputGroup
                                                                    style={{ gap: '0.3rem' }}>
                                                                    <Select
                                                                        label={t('Choose Assets')}
                                                                        name="assets"
                                                                        disabled={true}
                                                                        value={
                                                                            assetName
                                                                                ? assets?.find(
                                                                                      (e: any) =>
                                                                                          e?.label ==
                                                                                          assetName
                                                                                  )?.label
                                                                                : ''
                                                                        }
                                                                        onChange={e => {
                                                                            setAssetData(e);
                                                                            setAssetName(e?.name);
                                                                        }}
                                                                        // isMulti={false}
                                                                        options={assets.filter(
                                                                            (e: any) =>
                                                                                !addedAssets.some(
                                                                                    (re: any) =>
                                                                                        re.name ==
                                                                                        e.label
                                                                                )
                                                                        )}
                                                                        error={errors.assets as any}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={5}>
                                                                <InputGroup>
                                                                    <Input
                                                                        name={'quantity'}
                                                                        label={`${t('Quantity')}`}
                                                                        allowOnlyNumbersAndDecimal={
                                                                            true
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            const trimmedValue =
                                                                                parseFloat(
                                                                                    e?.value
                                                                                ) || 0;
                                                                            setAssetQuantity(
                                                                                trimmedValue.toString()
                                                                            );
                                                                        }}
                                                                        value={assetQuantity}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col lg={2}>
                                                                {' '}
                                                                <AddAssets
                                                                    onClick={() => {
                                                                        if (
                                                                            assetName &&
                                                                            assetQuantity
                                                                        ) {
                                                                            setAddedAssets(
                                                                                (
                                                                                    prevState: any
                                                                                ) => [
                                                                                    ...prevState,
                                                                                    {
                                                                                        name: assetName,
                                                                                        quantity:
                                                                                            assetQuantity,
                                                                                        asset: assetData,
                                                                                    },
                                                                                ]
                                                                            );
                                                                            setAssetQuantity(0);
                                                                            setAssetName('');
                                                                            setAssetData('');
                                                                        }
                                                                    }}>
                                                                    {t('Add')}
                                                                </AddAssets>
                                                            </Col>
                                                        </Row>
                                                    </Wrapper>
                                                    <Wrapper>
                                                        {addedAssets?.length
                                                            ? addedAssets?.map(
                                                                  (e: any, index: number) => (
                                                                      <div
                                                                          key={index}
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent:
                                                                                  'space-between',
                                                                              alignItems: 'center',
                                                                              border: '1px solid #ccc',
                                                                              padding: '10px',
                                                                              marginBottom: '10px',
                                                                              borderRadius:
                                                                                  '0.325rem',
                                                                          }}>
                                                                          {editingAssetIndex ===
                                                                          index ? (
                                                                              <Wrapper>
                                                                                  <Row alignItems="center">
                                                                                      <Col lg={5}>
                                                                                          <InputGroup
                                                                                              style={{
                                                                                                  gap: '0.3rem',
                                                                                              }}>
                                                                                              <Select
                                                                                                  label={t(
                                                                                                      'Choose Assets'
                                                                                                  )}
                                                                                                  name="assets"
                                                                                                  disabled={
                                                                                                      true
                                                                                                  }
                                                                                                  value={
                                                                                                      editAssetName
                                                                                                          ? assets?.find(
                                                                                                                (
                                                                                                                    e: any
                                                                                                                ) =>
                                                                                                                    e?.label ==
                                                                                                                    editAssetName
                                                                                                            )
                                                                                                                ?.label
                                                                                                          : ''
                                                                                                  }
                                                                                                  onChange={e => {
                                                                                                      setEditAssetName(
                                                                                                          e?.name
                                                                                                      );
                                                                                                  }}
                                                                                                  options={assets.filter(
                                                                                                      (
                                                                                                          e: any
                                                                                                      ) =>
                                                                                                          !addedAssets.some(
                                                                                                              (
                                                                                                                  re: any
                                                                                                              ) =>
                                                                                                                  re.name ==
                                                                                                                  e.label
                                                                                                          )
                                                                                                  )}
                                                                                                  error={
                                                                                                      errors.assets as any
                                                                                                  }
                                                                                              />
                                                                                          </InputGroup>
                                                                                      </Col>
                                                                                      <Col lg={4}>
                                                                                          <InputGroup>
                                                                                              <Input
                                                                                                  name={
                                                                                                      'quantity'
                                                                                                  }
                                                                                                  label={`${t(
                                                                                                      'Quantity'
                                                                                                  )}`}
                                                                                                  allowOnlyNumbersAndDecimal={
                                                                                                      true
                                                                                                  }
                                                                                                  onChange={(
                                                                                                      e: any
                                                                                                  ) => {
                                                                                                      setEditAssetQuantity(
                                                                                                          e?.value
                                                                                                      );
                                                                                                  }}
                                                                                                  value={
                                                                                                      editAssetQuantity
                                                                                                  }
                                                                                              />
                                                                                          </InputGroup>
                                                                                      </Col>
                                                                                      <Col
                                                                                          lg={2}
                                                                                          style={{
                                                                                              display:
                                                                                                  'flex',
                                                                                              alignItems:
                                                                                                  'center',
                                                                                              gap: '0.2rem',
                                                                                          }}>
                                                                                          {' '}
                                                                                          <AddAssets
                                                                                              style={{
                                                                                                  padding:
                                                                                                      '0.2rem 0.85rem',
                                                                                              }}
                                                                                              onClick={() => {
                                                                                                  if (
                                                                                                      editAssetName &&
                                                                                                      editAssetQuantity
                                                                                                  )
                                                                                                      handleUpdateAsset(
                                                                                                          index
                                                                                                      );
                                                                                              }}>
                                                                                              Update
                                                                                          </AddAssets>
                                                                                          <AddAssets
                                                                                              style={{
                                                                                                  padding:
                                                                                                      '0.2rem 0.85rem',
                                                                                              }}
                                                                                              onClick={() =>
                                                                                                  handleCancelEdit()
                                                                                              }>
                                                                                              Cancel
                                                                                          </AddAssets>
                                                                                      </Col>
                                                                                  </Row>
                                                                              </Wrapper>
                                                                          ) : (
                                                                              <>
                                                                                  <div>
                                                                                      <span
                                                                                          style={{
                                                                                              fontWeight:
                                                                                                  'bold',
                                                                                          }}>
                                                                                          Name:
                                                                                      </span>{' '}
                                                                                      {e?.name}
                                                                                      <br />
                                                                                      <span
                                                                                          style={{
                                                                                              fontWeight:
                                                                                                  'bold',
                                                                                          }}>
                                                                                          Quantity:
                                                                                      </span>{' '}
                                                                                      {e?.quantity}
                                                                                  </div>
                                                                                  <div
                                                                                      style={{
                                                                                          display:
                                                                                              'flex',
                                                                                          gap: '0.5rem',
                                                                                      }}>
                                                                                      <div
                                                                                          style={{
                                                                                              cursor: 'pointer',
                                                                                          }}
                                                                                          onClick={() =>
                                                                                              handleEditAsset(
                                                                                                  index
                                                                                              )
                                                                                          }>
                                                                                          <i className="fal fa-edit"></i>{' '}
                                                                                      </div>
                                                                                      <div
                                                                                          style={{
                                                                                              cursor: 'pointer',
                                                                                          }}
                                                                                          onClick={() =>
                                                                                              handleDeleteAsset(
                                                                                                  e
                                                                                              )
                                                                                          }>
                                                                                          <i className="fal fa-trash-alt"></i>{' '}
                                                                                      </div>
                                                                                  </div>
                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  )
                                                              )
                                                            : ''}
                                                    </Wrapper>

                                                    <Wrapper>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <InputGroup>
                                                                    <Input
                                                                        name={'price'}
                                                                        label={`${t('Price')}(${
                                                                            _userData.user
                                                                                .business_id
                                                                                .currency
                                                                        })`}
                                                                        allowOnlyNumbersAndDecimal={
                                                                            true
                                                                        }
                                                                        onChange={onChange}
                                                                        value={getValues('price')}
                                                                        error={errors.price as any}
                                                                    />
                                                                </InputGroup>

                                                                <InnerContent>
                                                                    <AlignFlex padding={'0'}>
                                                                        <div>
                                                                            {t('Show On Widget')}
                                                                        </div>
                                                                        <Switch
                                                                            value={selfBooking}
                                                                            onChange={() =>
                                                                                setSelfBooking(
                                                                                    false
                                                                                )
                                                                            }
                                                                        />
                                                                    </AlignFlex>
                                                                    <AlignFlex padding={'0'}>
                                                                        <div>
                                                                            {t(
                                                                                'Reservation by phone'
                                                                            )}
                                                                        </div>
                                                                        <Switch
                                                                            value={
                                                                                reservationByPhone
                                                                            }
                                                                            onChange={() =>
                                                                                setReservationByPhone(
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                    </AlignFlex>
                                                                    {/* <AlignFlex>
                                                                        <div>{t('Is Video?')}</div>
                                                                        <Switch
                                                                            value={is_video}
                                                                            onChange={val =>
                                                                                setIs_video(val)
                                                                            }
                                                                        />
                                                                    </AlignFlex> */}
                                                                    <AlignFlex>
                                                                        <div>
                                                                            {t('Service Color')}
                                                                        </div>
                                                                        <div
                                                                            className={`${s.service_colorPicker} service-color-${serviceColor}--bd-light`}
                                                                            onClick={() =>
                                                                                setShowColorModal(
                                                                                    true
                                                                                )
                                                                            }>
                                                                            <Icon
                                                                                className={`fal fa-pen`}></Icon>
                                                                        </div>
                                                                    </AlignFlex>
                                                                </InnerContent>
                                                                <FooterButton>
                                                                    <Button
                                                                        type="submit"
                                                                        ifClicked={() => {
                                                                            if (ifClicked) {
                                                                                navigate(
                                                                                    '/services'
                                                                                );
                                                                            }
                                                                        }}
                                                                        label={t('Add Service')}
                                                                        bgtype="secondary"></Button>
                                                                </FooterButton>
                                                            </Col>
                                                        </Row>
                                                    </Wrapper>
                                                </CardBody>
                                            </LeftCol>
                                        </Row>
                                    </Wrapper>
                                </Card>
                            </Col>
                        </Row>
                    </Wrapper>
                </form>

                {showColorModal && (
                    <Model tabIndex={-1} onClick={closeModal}>
                        <ModelBody>
                            <ModelHeader>
                                <Header>
                                    <Heading>{t('Service Colors')}</Heading>
                                    <Cross
                                        className="fal fa-times"
                                        onClick={() => setShowColorModal(false)}></Cross>
                                </Header>
                            </ModelHeader>
                            <ModelContent>
                                <ModelWrapper>
                                    <Row style={{ justifyContent: 'center' }}>
                                        {[...Array(32)].map((x, i) => (
                                            <Color key={i}>
                                                <div
                                                    className={` ${s.colorPicker_serviceColor} service-color-${i}--bd-light`}
                                                    onClick={() => changeServiceColor(i)}>
                                                    {serviceColor === i && (
                                                        <IconCover>
                                                            <i className="fal fa-check"></i>
                                                        </IconCover>
                                                    )}
                                                </div>
                                            </Color>
                                        ))}
                                    </Row>
                                </ModelWrapper>
                            </ModelContent>
                        </ModelBody>
                    </Model>
                )}
            </Inner>
        </MainContent>
    );
};

export const MainContent = styled.div`
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 2.7rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    ${media.xs`
	height: auto;
  `}
    ${media.sm`
	height: 100%;
  `}
	${media.md`
	height: 100%;
  `}
	${media.lg`
	height: 100%;
  `}
`;

const Inner = styled.div`
    margin-left: auto !important;
    margin-right: auto !important;
`;

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const Header = styled(Col)`
    display: flex;
    justify-content: space-between;
`;

const LeftCol = styled(Col)``;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const AlignFlex = styled.div<any>`
    padding-top: ${({ padding }) => (padding ? padding : '1rem')} !important;
    padding-bottom: 1rem !important;
    border-bottom: 0.1rem solid ${palette.grey.lightest}!important;
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
`;

const CardBody = styled.div`
    padding: 3rem 2rem 1.75rem 2rem !important;
    ${media.xs`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
    ${media.sm`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
	${media.md`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
	${media.lg`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
`;

const Icon = styled.i`
    font-size: 1.125rem;
    color: ${palette.white};
`;

const InnerContent = styled.div`
    padding-bottom: 1rem !important;
`;

const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.71rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled.div`
    min-width: 45.71rem;
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 1.71rem;
    width: 50rem;
    ${media.xs`
	min-width: 25rem;
  `}
    ${media.sm`
	min-width: 35rem;
  `}
	${media.md`
	min-width: 40rem;
  `}
	${media.lg`
	min-width: 45.71rem;
  `}
`;

const ModelHeader = styled.div`
    padding-top: 0rem !important;
    flex: 1 1;
    display: flex;
`;

const ModelContent = styled.div`
    padding-top: 2.5rem !important;
`;

const ModelWrapper = styled(Container)`
    height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    ${media.xs`
	height: 22rem;
  `}
    ${media.sm`
	height: 24rem;
  `}
	${media.md`
	height: 24rem;
  `}
	${media.lg`
	height: 28rem;
  `}
`;

const Color = styled.div`
    margin-bottom: 1rem;
    padding: 0.3rem;
`;

const IconCover = styled.div`
    color: ${palette.white};
    font-size: 2rem;
`;

export const AddAssets = styled.div`
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    padding: 0.7rem 1.85rem;
    color: ${palette.white};
    background: ${palette.secondary};
    box-shadow: none;
    margin: undefined;
    cursor: pointer;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    white-space: nowrap;
    -webkit-transition: all ease 0.25s;
    transition: all ease 0.25s;
`;

CreateService.Layout = Layout;
export default CreateService;
